import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index-onepage',
  templateUrl: './index-onepage.component.html',
  styleUrls: ['./index-onepage.component.css']
})

/**
 * Onepage Component
 */
export class IndexOnepageComponent implements OnInit {

  //Nav Bar Links
  firstLink ='About';
  secondLink ='Services';
  thirdLink ='Pricing';
  forthLink ='Our Team';
  fifthLink ='Contact';
  calltoaction = 'Call Now';
  emailtoaction = 'Email Now';
  callLink = 'tel:+61477577527'
  emailLink = 'mailto:hello@dite.com.au'
  

//client information
  brand = 'Dite Marketing';
  slogan = 'Digital Accelerated';
  slogan2 = 'Web. Search. Social.'
  introSalutation = 'Welcome To Dite Marketing';
  location = "We're Proudly Located In Brisbane, Australia. We Offer A Great Range Of Digital Marketing Solutions For Business Of All Sizes"
  introService = "We Implement The Latest Technology In Web & Strategies In Search & Social Media Marketing";
  introCallToAction = "Connect With Us Today.";

  secondaryTitle = "Digital Is Accelerated With Dite Marketing.";
  secondaryCallToAction = "Get Ready To Expedite Your Digital Marketing Strategy For Launch Tomorrow.";
  secondaryDescription = "Dite Marketing Starts From Scratch. Building From The Ground Up To Keep It Simple. Effective & Advanced Solutions. Previous Assets Utilisation Available. Dite Marketing Proudly Operates To The 3-D Strategy To Digital Marketing.";
  
  dotpoint1 = "Develop";
  dotpoint1Desc = "From The Onset We Develop An Ever Evolving Strategy In Response To Nature. Develop Creates Direction. After Which We Deploy";

  dotpoint2 = "Direction";
  dotpoint2Desc = "We Double Down On Strategy Built On Historicly Acquired Success, Agreed Metrics, Technologically Advanced Industry Products & Teamwork. We Develop To Push Direction. We Deploy To Enact What We Develop";

  dotpoint3 = "Deploy";
  dotpoint3Desc = "We Deploy. We Double Back To Direction To Inform How We Further Develop. The Exchange, The Interconnection Of Our Underlying 3-D Strategy Provides Uncompromisable Returns In Digital Marketing Aggregate Efficiency";

//second section - secondlink

servicesIntro = "Find Out More About The Potential Here At ";
servicesOverview = "We Offer A Range Of Digital Marketing Products To Help Expedite Your Brand, Business & Identity Into The Digital Universe. We Have An Exceptional Grasp Across A Number Of Suites, Products & Services Mentioned Below. Get In Touch To Find Out Exact Pricing & Book A Strategy Session With One Of Our Experts To Get An Understand Of Time & Turn Around. We're Ahead Of The Game On Our Products Underlying Technology. We're Pretty Confident In What We Do.";


//third section - pricing

pricingDesc = "We Offer Solutions Catered To All Brands & Budgets. From Entry Level Web Pages & Full Scale Web Applications, Paid Search & Organic Search Management With Expert Content Creation & Copywriting. We Also Provide Full Engagement In Social Media Marketing. Get Your Digital Accelerated With One Of Our Possibilities Below. Feel Free To Call Direct To Cater A Bespoke Solution Beyond What Is Mentioned. We Got You! ";

//forth section
ourteamDesc = "Pound For Pound There Isn't Anything Like Us. We Deliver Exceptional Results With Our Tight-Knit Team. How? We're All In This Together. Team-Team Attitude. We Built This From Scratch. All For One, One For All. If I Win, We Win. Dite Is Us. This Is Our Vehicle Of Expression To Make An Impact On The Digital Marketing World With Our Talents. We Educate. We're Transparent. We're Here To Develop Our Peers & Clients. Get In Touch To Network. We're Always On The Lookout For Other Who Can Define Their Own Role & Initiative.";
//fifth section
contactDesc = "Whether You're Looking To Expand With Our Services Or Provide Your Abilities To Further Enhance Our Team, We're Always Available For A Chat. Have A Great Day & We Look Forward To Speaking Soon.";
contactHook = "Hope You've Enjoyed The Experience To This Point. We Can Help Educate In Giving Your Brand & Business The Forward Thinking Approach Of Digital Accelerated. Give Us A Chance Across Web, Search & Social Avenues. You'll Be Amazed At What We Can Achieve. It's No News To Us. It's Time For Us To Share This With The World.";

  constructor() { }
  

  /**
   * Services Data
   */
  servicesData = [
    {
      icon: "bi bi-globe h1 text-primary",
      title: "Web Page Development",
      description: "Put Your Best Foot Forward With A Progressive Web Page Built In A Modern Javascript-Compiled, Front-End Framework. Get Rooted In Success From Launch With Breakneck Speeds That Demand Instant Attention. Sleek, Faultless, Mobile First Design On Pre-Rendered Edge CDN Deployment Networks. Zero Downtime. Unlimited Scalability. Great Entry Level Production. Goodbye Server Maintenance. This Is A Must Have If You're Looking To Compete In Google Ads In 2021. Sorry Wordpress."
    },
    {
      icon: "bi bi-search h1 text-primary",
      title: "Google Ads Management - Paid Search",
      description: "What Do You Get When You Put Triple Threat Web Developer/Content Writing/Graphic Designing Professionals In Charge Of Your Google Ads Account? I'll Keep It Short, You'd Better Call & Find Out. Successfully Managed, Manually Adjusted, Extremely Articulated Attention To Detail That Cuts The Unnecessary Spend, Delivering Exception Return On Ad Spend."
    },
    {
      icon: "bi bi-tv h1 text-primary",
      title: "Search Engine Optimisation - Organic Search",
      description: "Remember Those Triple Threat Professionals We Mentioned Earlier. We'll Now Apply That Across Your DNS Settings, Google Search Console, Robots.txt, Sitemap.XML & You're Prepared For Immediate Action. The Ongoing Art Is In The Content, So Enjoy Supurbly Articulated, Geo-Locational Content To Drive Your Web Traffic."
    },
    {
      icon: "bi bi-pen h1 text-primary",
      title: "Content Creation & Copywriting",
      description: "Building Amazing Technological Solutions Is Finite, But Awesome Content Is Fresh & Always Possible. You May Not Be Able To Reinvent The Wheel, But You Sure As Hell Can Rewrite About It. Remember That Teacher That Always Told You To 'Go Back, Re-Read What You've Written & Try Again?' Well Imagine The Results You Get When They Put Their Teaching Degree Into Content Creation."
    },
  ];

  leftovers = [{ 
   list: ["Landing Page Experience", "Curated Content", "PWA Ready", "On-Going Support", "On-Going Support", "PWA Ready",],
  }];
  /**
   * Pricing Data
   */
  pricingData = [
    {
      title: "Web Page Development",
      upfront: 'Upfront',
      priceUpfront: '9 999.99',
      linkUpfront: "https://buy.stripe.com/fZeeWV5QHayK9uo8wx",
      weekly: 299.99, 
      linkWeekly: "https://buy.stripe.com/6oE8yx6UL6iu8qk9AC",
      tax: '+ G.S.T',
      list1title: "Web Design",
      list1: ["Graphic Design", "Typography/Page Layout", "User Experience Design",  "User Interface Design", "Responsive Web Design", "Figma Prototyping", "Written Content Creation", "Page SEO Setup"],
      list2title: "Web Development",
      list2: [ "Front-end Frameworks", "HTML/CSS/JS", "SASS/NPM/Angular 12+", "Server Side Setups", "Linode/CyberPanel","Linux Server", "PHP/MySQL/MongoDB", "NodeJS/ExpressJs" ],
      prefix: 'Weekly',
      term:  '',
      btn: "Buy Now",
     
    },
    {
      title: "Google Ads Account Management",
      upfront: 'Upfront',
      priceUpfront: '5 199.99',
      linkUpfront: "https://buy.stripe.com/00gaGF3IzeP04a48wz",
      weekly: 129.99, 
      linkWeekly: "https://buy.stripe.com/3cs6qp7YP22ecGA28c",
      tax: '+ G.S.T',
      list1title: 'Google Analytics',
      list1: [ "User Audience Creation", "Demographic Analyses", "Device Population", "Delivery Optimisation", "Daily Monitoring", "Light House Report Updates", "24 Hour Live Chat & Response" ],
      list2title: "Google Ads Setup",
      list2: [ "Search Campaigns", "Display Campaigns", "Remarketing Campaigns", "Shopping Campaigns", "Individualise Strategy", "Time Specific Content","Lead Generation", "Full Google Suite" ],
      prefix: 'Weekly',
      term:  '',
      btn: "Buy Now",
    },
    {
      title: "Search Engine Optimisation",
      upfront: 'Upfront',
      priceUpfront: '23 999.99',
      linkUpfront: "https://buy.stripe.com/28o6qpcf57mygWQ8wB",
      weekly: 699.99, 
      linkWeekly: "https://buy.stripe.com/8wMaGF6UL22eeOI4gm",
      tax: '+ G.S.T',
      list1title: 'Google Search Console',
      list1: ["Google Console Setup", "Robots.txt Implementation", "Sitemap.xml Creation", "Weekly Article Population", "Meticulous Metadata Entry", "Extensive Localised Approach"],
      list2title: "SEM Rush Monitoring",
      list2: [ "Real-time Keyword Tracking ", "Complete Keyword Focus", "Extensive Backlinking", "Geo Location Specific", "1000's Of Unique Webpages Created"],
      prefix: 'Weekly',
      term:  '',
      btn: "Buy Now",
    },
    {
      title: "Content Creation & Copywriting",
      upfront: 'Upfront',
      priceUpfront: '9 999.99',
      linkUpfront: "https://buy.stripe.com/8wM4ih3Iz5eq21W008",
      weekly: 199.99, 
      linkWeekly: "https://buy.stripe.com/bIYg0Z4MD6iu9uo14d",
      tax: '+ G.S.T',
      list1title: 'Extensive Research',
      list1: ["Hero Image Graphic Design", "Metadata & Description Implementation", "Weekly Articles", "Keyword & Location Individuality", "Industry Specific Understanding"],
      list2title: "Professional Copywriting",
      list2: [ "Pillar & Post Content", "Engaging Content Structuring", "Educational & Informing Content", "Search Engine Optimisation Boost", "Back & Refferal Linking", "Affiliate Marketing"],
      prefix: 'Weekly',
      term:  '',
      btn: "Buy Now",
    },
  ];

  /**
   * Member Data
   */
  memberData = [
    {
      profile: "assets/images/dite-one-page/01.png",
      name: "Akhia Karaitiana",
      designation: "Co-Founder - Digital Strategist"
    },
    {
      profile: "assets/images/dite-one-page/02.png",
      name: "Dan Andreotti",
      designation: "Co-Founder - Client Experience"
    },
    {
      profile: "assets/images/dite-one-page/04.png",
      name: "Jess Greene",
      designation: "Partner - Accounting"
    }
  ];

  ngOnInit(): void {
  }

}
