<header><nav class="navbar fixed-top navbar-light bg-light">
  <img routerLink="/" src="assets/images/dite-one-page/logo.png" height="80" alt="dite-digitally-accelerated" class="mx-auto d-none d-lg-block">
  <img src="assets/images/dite-one-page/logo.png" height="60" alt="dite-digitally-accelerated" class="mx-auto d-block d-lg-none">
</nav>
<nav class="navbar fixed-top2 navbar-light bg-light nav nav-pills nav-fill">
  <a class="nav-item nav-link text-primary" [ngxScrollTo]="'about'">{{this.firstLink}}</a>
  <a class="nav-item nav-link" [ngxScrollTo]="'service'">{{this.secondLink}}</a>
  <a class="nav-item nav-link" [ngxScrollTo]="'pricing'">{{this.thirdLink}}</a>
  <a class="nav-item nav-link" [ngxScrollTo]="'ourteam'">{{this.forthLink}}</a>
  <a class="nav-item nav-link" [ngxScrollTo]="'contact'">{{this.fifthLink}}</a>
   </nav>
  </header>

<!-- Navbar End -->
  <!-- Hero Start -->
  <div style="height: 180px;"></div>
  <section class="bg-home bg-light d-flex align-items-center">
    <div class="container">
      <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 justify-content-center">
        <div class="col-sm text-center pt-0 pt-md-5">
          <h1 class="mb-3">Thank You For Your Purchase<br>We Will Be In Contact Soon.</h1>
        </div>
      </div>
      <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 justify-content-center">
        <div class="col-sm text-center pt-0 pt-md-5">
            <h1 class="mb-3">{{ this.brand }}</h1>
          </div>
            <div class="col-sm text-center pt-0 pt-md-5">
            <h2><span class="text-primary">{{ this.slogan }}</span><br>
            <span class="text-primary">{{ this.slogan2 }}</span>
          </h2>
            </div>
            <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 justify-content-center">
            <p class="text-muted">{{ this.introSalutation }}. {{ this.location }}. {{ this.introService }}. {{ this.introCallToAction }}</p>              
              </div>
          </div>
          <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 justify-content-center onepage-hero">
            <img src="assets/images/dite-one-page/google-ads-1.png" alt="" class="img-fluid">
          </div>
        </div>
        <!--end col-->

      <!--end row-->

    <!--end container-->
  </section>

  <!--end section-->
  <!-- Hero End -->
  <!-- About 2-->
  <div class="container" id="about" style="height: 75px;">
    <div class="row col-12 justify-content-center">
    <div class="col-5 mt-5 text-center" >
    <button class="btn btn-lg btn-secondary"><a href="{{ this.callLink }}" class="text-reset"><i class="bi bi-telephone"></i> {{ this.calltoaction }}</a></button>
  </div>
  <div class="col-5 mt-5 text-center" >
    <button class="btn btn-lg btn-secondary" ><a href="{{ this.emailLink }}" class="text-reset"><i class="bi bi-envelope"></i> {{ this.emailtoaction }}</a></button>
  </div>
</div>
</div>
  
  <div class="container mt-100 mt-60 mb-100 mb-60">
    <div class="row align-items-center">
      <div class="col-xl-4 col-lg-4 col-md-4">
        <img src="assets/images/dite-one-page/mobile01.png" class="img-fluid" alt="mobile-design-dite"
        />
      </div>
      <!--end col-->
      

      <div class="col-xl-8 col-lg-8 col-md-8 mt-2 mt-sm-0 pt-2 pt-sm-0" >
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4"> {{ this.secondaryTitle }}</h4>
          <p class="text-muted"> {{ this.secondaryCallToAction }}
          </p>
          <p class="text-muted">
            {{ this.secondaryDescription }}
          </p>
          <ul class="list-unstyled mb-0 text-muted">
            <li class="mb-0">
              <span class="text-primary h5 me-2"
                ><i class="bi bi-check-circle"></i></span
              >{{ this.dotpoint1 }}
            </li>
            <p
              class="text-muted text-justify">
              {{ this.dotpoint1Desc }}
            </p>
            <li class="mb-0">
              <span class="text-primary h5 me-2"
                ><i class="bi bi-check-circle"></i></span
              >{{ this.dotpoint2 }}
            </li>
            <p
              class="text-muted text-justify">
              {{ this.dotpoint2Desc }}
            </p>
            <li class="mb-0">
              <span class="text-primary h5 me-2"
                ><i class="bi bi-check-circle"></i></span
              >{{ this.dotpoint3 }}
            </li>
            <p
              class="text-muted text-justify">
              {{ this.dotpoint3Desc }}
            </p>
          </ul>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!--About 2 End-->
  <div id="service" style="padding-top: 50px"></div>
  <!-- Services Start -->
  <section class="section bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12 col-sm-12 text-center">
            <div class="col-12">
            <h1 class="mb-4 mt-5">
              {{ this.secondLink }}
            </h1>
            <p class="text-muted mb-0 text-start">
              {{this.servicesIntro}} <span class="text-primary fw-bold">{{ this.brand }}.</span> {{ this.servicesOverview }}
            </p>
          
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
      <app-services [servicesData]="servicesData"></app-services>
    </div>

    <div id="pricing" style="padding-top: 50px"></div>
    <section class="section bg-light">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 text-center">
            <div class="mb-4 pb-2">
              <h1 class="mb-4">Our Pricing</h1>
              <p class="text-muted">{{ this.pricingDesc }}</p>
            </div>
          </div>
        </div>
        <app-pricing [pricingData]="pricingData"></app-pricing>
      </div>
    </section>

<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="position-relative" style="z-index: 1">
        <img src="assets/images/dite-one-page/laptop.png" class="rounded img-fluid mx-auto d-block" alt="we-are-digital-get-in-touch"/></div>
        </div>
      </div>
      <!--end row-->
    </div>
  <!--end section-->
  <div class="position-relative">
    <div class="shape overflow-hidden text-light">
      <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path></svg>
    </div>
  </div>
  <!-- Features End -->
  <div id="ourteam" style="padding-top: 50px"></div>
  <!-- Team Start -->
  <section class="section bg-light">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 text-center">
          <div class="mb-4 pb-2">
            <h1 class="mb-4">{{ this.forthLink }}</h1>
            <p class="text-muted">{{ this.ourteamDesc }}</p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
      <app-member [memberData]="memberData"></app-member>
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Team End -->

 
  <div id="contact"  style="padding-top: 50px"></div>
 <!-- Contact Start -->
  <section class="section bg-light">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 text-center">
          <div class="section-title mb-4 pb-2 ">
            <h1 class="text-center">Get In {{this.fifthLink}} Today</h1>
            <p class="text-muted">{{this.contactHook}} {{this.contactDesc}}<br> Kind Regards,<br>
              <span class="text-primary fw-bold">{{ this.brand }}</span>
            </p>
          </div>
        </div>
        <!--end col-->

            <!--Contact Elements Email Start -->
            <div class="col-lg-6 col-md-6 d-flex align-items-center ">
              <div class="card-body p-0 d-flex justify-content-evenly">
                <div class="icon btn btn-primary mt-2 mb-2">
                    <i class="bi bi-envelope me-3"></i>
                    <a href="{{this.emailLink}}" class="text-light">{{this.emailtoaction}}</a>
                </div>                   
              </div>
            </div>
            <!--Contact Elements Email End -->
        
            <!--Contact Elements Phone Start -->
            <div class="col-lg-6 col-md-6 d-flex align-items-center ">
              <div class="card-body p-0 d-flex justify-content-evenly">
                  <div class="icon btn btn-primary mb-2 mt-2">
                    <i class="bi bi-telephone"></i>
                    <a href="{{this.callLink}}" class="text-light">{{this.calltoaction}}</a>
                  </div>  
                </div>
              </div>
            <!--Contact Elements Phone End -->
            <!--Contact Elements Location Start -->
          </div>
<!--Contact Elements Location End -->

             

            
              
    <!--end contact-->

    <div class="container-fluid mt-100 mt-60">
      <div class="row">
        <div class="col-12 p-0">
          <div class="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d160168.27447516724!2d152.9868195987264!3d-27.492528501229607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b91579aac93d233%3A0x402a35af3deaf40!2sBrisbane%20QLD!5e0!3m2!1sen!2sau!4v1624340329851!5m2!1sen!2sau"
              style="border: 0; height: 600px"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
 
  <!--end section-->
  <!-- Contact End -->

  <!-- Back to top -->
  <!-- Back to top -->
  <!-- Footer-->
  </div>

</section>