import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-marketing-sunnybank',
  templateUrl: './digital-marketing-sunnybank.component.html',
  styleUrls: ['./digital-marketing-sunnybank.component.css']
})
export class DigitalMarketingSunnybankComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
