import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DigitalMarketingAcaciaRidgeComponent } from './digital-marketing-acacia-ridge/digital-marketing-acacia-ridge.component';
import { DigitalMarketingAlbionComponent } from './digital-marketing-albion/digital-marketing-albion.component';
import { DigitalMarketingAlderleyComponent } from './digital-marketing-alderley/digital-marketing-alderley.component';
import { DigitalMarketingAlgesterComponent } from './digital-marketing-algester/digital-marketing-algester.component';
import { DigitalMarketingAnnerleyComponent } from './digital-marketing-annerley/digital-marketing-annerley.component';
import { DigitalMarketingAnsteadComponent } from './digital-marketing-anstead/digital-marketing-anstead.component';
import { DigitalMarketingArcherfieldComponent } from './digital-marketing-archerfield/digital-marketing-archerfield.component';
import { DigitalMarketingAscotComponent } from './digital-marketing-ascot/digital-marketing-ascot.component';
import { DigitalMarketingAshgroveComponent } from './digital-marketing-ashgrove/digital-marketing-ashgrove.component';
import { DigitalMarketingAspleyComponent } from './digital-marketing-aspley/digital-marketing-aspley.component';
import { DigitalMarketingAuchenflowerComponent } from './digital-marketing-auchenflower/digital-marketing-auchenflower.component';
import { DigitalMarketingBaldHillsComponent } from './digital-marketing-bald-hills/digital-marketing-bald-hills.component';
import { DigitalMarketingBalmoralComponent } from './digital-marketing-balmoral/digital-marketing-balmoral.component';
import { DigitalMarketingBanksCreekComponent } from './digital-marketing-banks-creek/digital-marketing-banks-creek.component';
import { DigitalMarketingBanyoComponent } from './digital-marketing-banyo/digital-marketing-banyo.component';
import { DigitalMarketingBardonComponent } from './digital-marketing-bardon/digital-marketing-bardon.component';
import { DigitalMarketingBellbowrieComponent } from './digital-marketing-bellbowrie/digital-marketing-bellbowrie.component';
import { DigitalMarketingBelmontComponent } from './digital-marketing-belmont/digital-marketing-belmont.component';
import { DigitalMarketingBoondallComponent } from './digital-marketing-boondall/digital-marketing-boondall.component';
import { DigitalMarketingBowenHillsComponent } from './digital-marketing-bowen-hills/digital-marketing-bowen-hills.component';
import { DigitalMarketingBrackenRidgeComponent } from './digital-marketing-bracken-ridge/digital-marketing-bracken-ridge.component';
import { DigitalMarketingBridgemanDownsComponent } from './digital-marketing-bridgeman-downs/digital-marketing-bridgeman-downs.component';
import { DigitalMarketingBrightonComponent } from './digital-marketing-brighton/digital-marketing-brighton.component';
import { DigitalMarketingBrisbaneAirportComponent } from './digital-marketing-brisbane-airport/digital-marketing-brisbane-airport.component';
import { DigitalMarketingBrisbaneComponent } from './digital-marketing-brisbane/digital-marketing-brisbane.component';
import { DigitalMarketingBrookfieldComponent } from './digital-marketing-brookfield/digital-marketing-brookfield.component';
import { DigitalMarketingBulimbaComponent } from './digital-marketing-bulimba/digital-marketing-bulimba.component';
import { DigitalMarketingBulwerComponent } from './digital-marketing-bulwer/digital-marketing-bulwer.component';
import { DigitalMarketingBurbankComponent } from './digital-marketing-burbank/digital-marketing-burbank.component';
import { DigitalMarketingCalamvaleComponent } from './digital-marketing-calamvale/digital-marketing-calamvale.component';
import { DigitalMarketingCampHillComponent } from './digital-marketing-camp-hill/digital-marketing-camp-hill.component';
import { DigitalMarketingCannonHillComponent } from './digital-marketing-cannon-hill/digital-marketing-cannon-hill.component';
import { DigitalMarketingCarinaHeightsComponent } from './digital-marketing-carina-heights/digital-marketing-carina-heights.component';
import { DigitalMarketingCarinaComponent } from './digital-marketing-carina/digital-marketing-carina.component';
import { DigitalMarketingCarindaleComponent } from './digital-marketing-carindale/digital-marketing-carindale.component';
import { DigitalMarketingCarseldineComponent } from './digital-marketing-carseldine/digital-marketing-carseldine.component';
import { DigitalMarketingChandlerComponent } from './digital-marketing-chandler/digital-marketing-chandler.component';
import { DigitalMarketingChapelHillComponent } from './digital-marketing-chapel-hill/digital-marketing-chapel-hill.component';
import { DigitalMarketingChelmerComponent } from './digital-marketing-chelmer/digital-marketing-chelmer.component';
import { DigitalMarketingChermsideWestComponent } from './digital-marketing-chermside-west/digital-marketing-chermside-west.component';
import { DigitalMarketingChermsideComponent } from './digital-marketing-chermside/digital-marketing-chermside.component';
import { DigitalMarketingChuwarComponent } from './digital-marketing-chuwar/digital-marketing-chuwar.component';
import { DigitalMarketingClayfieldComponent } from './digital-marketing-clayfield/digital-marketing-clayfield.component';
import { DigitalMarketingCoopersPlainsComponent } from './digital-marketing-coopers-plains/digital-marketing-coopers-plains.component';
import { DigitalMarketingCoorparooComponent } from './digital-marketing-coorparoo/digital-marketing-coorparoo.component';
import { DigitalMarketingCorindaComponent } from './digital-marketing-corinda/digital-marketing-corinda.component';
import { DigitalMarketingCowanCowanComponent } from './digital-marketing-cowan-cowan/digital-marketing-cowan-cowan.component';
import { DigitalMarketingDarraComponent } from './digital-marketing-darra/digital-marketing-darra.component';
import { DigitalMarketingDeagonComponent } from './digital-marketing-deagon/digital-marketing-deagon.component';
import { DigitalMarketingDoolandellaComponent } from './digital-marketing-doolandella/digital-marketing-doolandella.component';
import { DigitalMarketingDrewvaleComponent } from './digital-marketing-drewvale/digital-marketing-drewvale.component';
import { DigitalMarketingDurackComponent } from './digital-marketing-durack/digital-marketing-durack.component';
import { DigitalMarketingDuttonParkComponent } from './digital-marketing-dutton-park/digital-marketing-dutton-park.component';
import { DigitalMarketingEagleFarmComponent } from './digital-marketing-eagle-farm/digital-marketing-eagle-farm.component';
import { DigitalMarketingEastBrisbaneComponent } from './digital-marketing-east-brisbane/digital-marketing-east-brisbane.component';
import { DigitalMarketingEightMilePlainsComponent } from './digital-marketing-eight-mile-plains/digital-marketing-eight-mile-plains.component';
import { DigitalMarketingEllenGroveComponent } from './digital-marketing-ellen-grove/digital-marketing-ellen-grove.component';
import { DigitalMarketingEnglandCreekComponent } from './digital-marketing-england-creek/digital-marketing-england-creek.component';
import { DigitalMarketingEnoggeraReservoirComponent } from './digital-marketing-enoggera-reservoir/digital-marketing-enoggera-reservoir.component';
import { DigitalMarketingEnoggeraComponent } from './digital-marketing-enoggera/digital-marketing-enoggera.component';
import { DigitalMarketingEvertonParkComponent } from './digital-marketing-everton-park/digital-marketing-everton-park.component';
import { DigitalMarketingFairfieldComponent } from './digital-marketing-fairfield/digital-marketing-fairfield.component';
import { DigitalMarketingFernyGroveComponent } from './digital-marketing-ferny-grove/digital-marketing-ferny-grove.component';
import { DigitalMarketingFigTreePocketComponent } from './digital-marketing-fig-tree-pocket/digital-marketing-fig-tree-pocket.component';
import { DigitalMarketingFitzgibbonComponent } from './digital-marketing-fitzgibbon/digital-marketing-fitzgibbon.component';
import { DigitalMarketingForestLakeComponent } from './digital-marketing-forest-lake/digital-marketing-forest-lake.component';
import { DigitalMarketingFortitudeValleyComponent } from './digital-marketing-fortitude-valley/digital-marketing-fortitude-valley.component';
import { DigitalMarketingGaythorneComponent } from './digital-marketing-gaythorne/digital-marketing-gaythorne.component';
import { DigitalMarketingGeebungComponent } from './digital-marketing-geebung/digital-marketing-geebung.component';
import { DigitalMarketingGordonParkComponent } from './digital-marketing-gordon-park/digital-marketing-gordon-park.component';
import { DigitalMarketingGracevilleComponent } from './digital-marketing-graceville/digital-marketing-graceville.component';
import { DigitalMarketingGrangeComponent } from './digital-marketing-grange/digital-marketing-grange.component';
import { DigitalMarketingGreenslopesComponent } from './digital-marketing-greenslopes/digital-marketing-greenslopes.component';
import { DigitalMarketingGumdaleComponent } from './digital-marketing-gumdale/digital-marketing-gumdale.component';
import { DigitalMarketingHamiltonComponent } from './digital-marketing-hamilton/digital-marketing-hamilton.component';
import { DigitalMarketingHawthorneComponent } from './digital-marketing-hawthorne/digital-marketing-hawthorne.component';
import { DigitalMarketingHeathwoodComponent } from './digital-marketing-heathwood/digital-marketing-heathwood.component';
import { DigitalMarketingHemmantComponent } from './digital-marketing-hemmant/digital-marketing-hemmant.component';
import { DigitalMarketingHendraComponent } from './digital-marketing-hendra/digital-marketing-hendra.component';
import { DigitalMarketingHerstonComponent } from './digital-marketing-herston/digital-marketing-herston.component';
import { DigitalMarketingHighgateHillComponent } from './digital-marketing-highgate-hill/digital-marketing-highgate-hill.component';
import { DigitalMarketingHollandParkWestComponent } from './digital-marketing-holland-park-west/digital-marketing-holland-park-west.component';
import { DigitalMarketingHollandParkComponent } from './digital-marketing-holland-park/digital-marketing-holland-park.component';
import { DigitalMarketingInalaComponent } from './digital-marketing-inala/digital-marketing-inala.component';
import { DigitalMarketingIndooroopillyComponent } from './digital-marketing-indooroopilly/digital-marketing-indooroopilly.component';
import { DigitalMarketingJamboreeHeightsComponent } from './digital-marketing-jamboree-heights/digital-marketing-jamboree-heights.component';
import { DigitalMarketingJindaleeComponent } from './digital-marketing-jindalee/digital-marketing-jindalee.component';
import { DigitalMarketingKangarooPointComponent } from './digital-marketing-kangaroo-point/digital-marketing-kangaroo-point.component';
import { DigitalMarketingKaranaDownsComponent } from './digital-marketing-karana-downs/digital-marketing-karana-downs.component';
import { DigitalMarketingKarawathaComponent } from './digital-marketing-karawatha/digital-marketing-karawatha.component';
import { DigitalMarketingKedronComponent } from './digital-marketing-kedron/digital-marketing-kedron.component';
import { DigitalMarketingKelvinGroveComponent } from './digital-marketing-kelvin-grove/digital-marketing-kelvin-grove.component';
import { DigitalMarketingKenmoreHillsComponent } from './digital-marketing-kenmore-hills/digital-marketing-kenmore-hills.component';
import { DigitalMarketingKenmoreComponent } from './digital-marketing-kenmore/digital-marketing-kenmore.component';
import { DigitalMarketingKeperraComponent } from './digital-marketing-keperra/digital-marketing-keperra.component';
import { DigitalMarketingKholoComponent } from './digital-marketing-kholo/digital-marketing-kholo.component';
import { DigitalMarketingKooringalComponent } from './digital-marketing-kooringal/digital-marketing-kooringal.component';
import { DigitalMarketingKurabyComponent } from './digital-marketing-kuraby/digital-marketing-kuraby.component';
import { DigitalMarketingLakeManchesterComponent } from './digital-marketing-lake-manchester/digital-marketing-lake-manchester.component';
import { DigitalMarketingLarapintaComponent } from './digital-marketing-larapinta/digital-marketing-larapinta.component';
import { DigitalMarketingLotaComponent } from './digital-marketing-lota/digital-marketing-lota.component';
import { DigitalMarketingLutwycheComponent } from './digital-marketing-lutwyche/digital-marketing-lutwyche.component';
import { DigitalMarketingLyttonComponent } from './digital-marketing-lytton/digital-marketing-lytton.component';
import { DigitalMarketingMacgregorComponent } from './digital-marketing-macgregor/digital-marketing-macgregor.component';
import { DigitalMarketingMackenzieComponent } from './digital-marketing-mackenzie/digital-marketing-mackenzie.component';
import { DigitalMarketingManlyWestComponent } from './digital-marketing-manly-west/digital-marketing-manly-west.component';
import { DigitalMarketingManlyComponent } from './digital-marketing-manly/digital-marketing-manly.component';
import { DigitalMarketingMansfieldComponent } from './digital-marketing-mansfield/digital-marketing-mansfield.component';
import { DigitalMarketingMcdowallComponent } from './digital-marketing-mcdowall/digital-marketing-mcdowall.component';
import { DigitalMarketingMiddleParkComponent } from './digital-marketing-middle-park/digital-marketing-middle-park.component';
import { DigitalMarketingMiltonComponent } from './digital-marketing-milton/digital-marketing-milton.component';
import { DigitalMarketingMitcheltonComponent } from './digital-marketing-mitchelton/digital-marketing-mitchelton.component';
import { DigitalMarketingMoggillComponent } from './digital-marketing-moggill/digital-marketing-moggill.component';
import { DigitalMarketingMoorookaComponent } from './digital-marketing-moorooka/digital-marketing-moorooka.component';
import { DigitalMarketingMoretonBayComponent } from './digital-marketing-moreton-bay/digital-marketing-moreton-bay.component';
import { DigitalMarketingMoretonIslandComponent } from './digital-marketing-moreton-island/digital-marketing-moreton-island.component';
import { DigitalMarketingMorningsideComponent } from './digital-marketing-morningside/digital-marketing-morningside.component';
import { DigitalMarketingMountCootThaComponent } from './digital-marketing-mount-coot-tha/digital-marketing-mount-coot-tha.component';
import { DigitalMarketingMountCrosbyComponent } from './digital-marketing-mount-crosby/digital-marketing-mount-crosby.component';
import { DigitalMarketingMountGravattEastComponent } from './digital-marketing-mount-gravatt-east/digital-marketing-mount-gravatt-east.component';
import { DigitalMarketingMountGravattComponent } from './digital-marketing-mount-gravatt/digital-marketing-mount-gravatt.component';
import { DigitalMarketingMountOmmaneyComponent } from './digital-marketing-mount-ommaney/digital-marketing-mount-ommaney.component';
import { DigitalMarketingMurarrieComponent } from './digital-marketing-murarrie/digital-marketing-murarrie.component';
import { DigitalMarketingMyrtletownComponent } from './digital-marketing-myrtletown/digital-marketing-myrtletown.component';
import { DigitalMarketingNathanComponent } from './digital-marketing-nathan/digital-marketing-nathan.component';
import { DigitalMarketingNewFarmComponent } from './digital-marketing-new-farm/digital-marketing-new-farm.component';
import { DigitalMarketingNewmarketComponent } from './digital-marketing-newmarket/digital-marketing-newmarket.component';
import { DigitalMarketingNewsteadComponent } from './digital-marketing-newstead/digital-marketing-newstead.component';
import { DigitalMarketingNormanParkComponent } from './digital-marketing-norman-park/digital-marketing-norman-park.component';
import { DigitalMarketingNorthgateComponent } from './digital-marketing-northgate/digital-marketing-northgate.component';
import { DigitalMarketingNudgeeBeachComponent } from './digital-marketing-nudgee-beach/digital-marketing-nudgee-beach.component';
import { DigitalMarketingNudgeeComponent } from './digital-marketing-nudgee/digital-marketing-nudgee.component';
import { DigitalMarketingNundahComponent } from './digital-marketing-nundah/digital-marketing-nundah.component';
import { DigitalMarketingOxleyComponent } from './digital-marketing-oxley/digital-marketing-oxley.component';
import { DigitalMarketingPaddingtonComponent } from './digital-marketing-paddington/digital-marketing-paddington.component';
import { DigitalMarketingPallaraComponent } from './digital-marketing-pallara/digital-marketing-pallara.component';
import { DigitalMarketingParkinsonComponent } from './digital-marketing-parkinson/digital-marketing-parkinson.component';
import { DigitalMarketingPetrieTerraceComponent } from './digital-marketing-petrie-terrace/digital-marketing-petrie-terrace.component';
import { DigitalMarketingPinjarraHillsComponent } from './digital-marketing-pinjarra-hills/digital-marketing-pinjarra-hills.component';
import { DigitalMarketingPinkenbaComponent } from './digital-marketing-pinkenba/digital-marketing-pinkenba.component';
import { DigitalMarketingPortOfBrisbaneComponent } from './digital-marketing-port-of-brisbane/digital-marketing-port-of-brisbane.component';
import { DigitalMarketingPullenvaleComponent } from './digital-marketing-pullenvale/digital-marketing-pullenvale.component';
import { DigitalMarketingRansomeComponent } from './digital-marketing-ransome/digital-marketing-ransome.component';
import { DigitalMarketingRedHillComponent } from './digital-marketing-red-hill/digital-marketing-red-hill.component';
import { DigitalMarketingRichlandsComponent } from './digital-marketing-richlands/digital-marketing-richlands.component';
import { DigitalMarketingRiverhillsComponent } from './digital-marketing-riverhills/digital-marketing-riverhills.component';
import { DigitalMarketingRobertsonComponent } from './digital-marketing-robertson/digital-marketing-robertson.component';
import { DigitalMarketingRochedaleComponent } from './digital-marketing-rochedale/digital-marketing-rochedale.component';
import { DigitalMarketingRockleaComponent } from './digital-marketing-rocklea/digital-marketing-rocklea.component';
import { DigitalMarketingRuncornComponent } from './digital-marketing-runcorn/digital-marketing-runcorn.component';
import { DigitalMarketingSalisburyComponent } from './digital-marketing-salisbury/digital-marketing-salisbury.component';
import { DigitalMarketingSandgateComponent } from './digital-marketing-sandgate/digital-marketing-sandgate.component';
import { DigitalMarketingSevenHillsComponent } from './digital-marketing-seven-hills/digital-marketing-seven-hills.component';
import { DigitalMarketingSeventeenMileRocksComponent } from './digital-marketing-seventeen-mile-rocks/digital-marketing-seventeen-mile-rocks.component';
import { DigitalMarketingSherwoodComponent } from './digital-marketing-sherwood/digital-marketing-sherwood.component';
import { DigitalMarketingShorncliffeComponent } from './digital-marketing-shorncliffe/digital-marketing-shorncliffe.component';
import { DigitalMarketingSinnamonParkComponent } from './digital-marketing-sinnamon-park/digital-marketing-sinnamon-park.component';
import { DigitalMarketingSouthBrisbaneComponent } from './digital-marketing-south-brisbane/digital-marketing-south-brisbane.component';
import { DigitalMarketingSpringHillComponent } from './digital-marketing-spring-hill/digital-marketing-spring-hill.component';
import { DigitalMarketingStLuciaComponent } from './digital-marketing-st-lucia/digital-marketing-st-lucia.component';
import { DigitalMarketingStaffordHeightsComponent } from './digital-marketing-stafford-heights/digital-marketing-stafford-heights.component';
import { DigitalMarketingStaffordComponent } from './digital-marketing-stafford/digital-marketing-stafford.component';
import { DigitalMarketingStrettonComponent } from './digital-marketing-stretton/digital-marketing-stretton.component';
import { DigitalMarketingSumnerComponent } from './digital-marketing-sumner/digital-marketing-sumner.component';
import { DigitalMarketingSunnybankHillsComponent } from './digital-marketing-sunnybank-hills/digital-marketing-sunnybank-hills.component';
import { DigitalMarketingSunnybankComponent } from './digital-marketing-sunnybank/digital-marketing-sunnybank.component';
import { DigitalMarketingTaigumComponent } from './digital-marketing-taigum/digital-marketing-taigum.component';
import { DigitalMarketingTaringaComponent } from './digital-marketing-taringa/digital-marketing-taringa.component';
import { DigitalMarketingTarragindiComponent } from './digital-marketing-tarragindi/digital-marketing-tarragindi.component';
import { DigitalMarketingTeneriffeComponent } from './digital-marketing-teneriffe/digital-marketing-teneriffe.component';
import { DigitalMarketingTennysonComponent } from './digital-marketing-tennyson/digital-marketing-tennyson.component';
import { DigitalMarketingTheGapComponent } from './digital-marketing-the-gap/digital-marketing-the-gap.component';
import { DigitalMarketingTingalpaComponent } from './digital-marketing-tingalpa/digital-marketing-tingalpa.component';
import { DigitalMarketingToowongComponent } from './digital-marketing-toowong/digital-marketing-toowong.component';
import { DigitalMarketingUpperBrookfieldComponent } from './digital-marketing-upper-brookfield/digital-marketing-upper-brookfield.component';
import { DigitalMarketingUpperKedronComponent } from './digital-marketing-upper-kedron/digital-marketing-upper-kedron.component';
import { DigitalMarketingUpperMountGravattComponent } from './digital-marketing-upper-mount-gravatt/digital-marketing-upper-mount-gravatt.component';
import { DigitalMarketingVirginiaComponent } from './digital-marketing-virginia/digital-marketing-virginia.component';
import { DigitalMarketingWacolComponent } from './digital-marketing-wacol/digital-marketing-wacol.component';
import { DigitalMarketingWakerleyComponent } from './digital-marketing-wakerley/digital-marketing-wakerley.component';
import { DigitalMarketingWavellHeightsComponent } from './digital-marketing-wavell-heights/digital-marketing-wavell-heights.component';
import { DigitalMarketingWestEndComponent } from './digital-marketing-west-end/digital-marketing-west-end.component';
import { DigitalMarketingWestlakeComponent } from './digital-marketing-westlake/digital-marketing-westlake.component';
import { DigitalMarketingWillawongComponent } from './digital-marketing-willawong/digital-marketing-willawong.component';
import { DigitalMarketingWilstonComponent } from './digital-marketing-wilston/digital-marketing-wilston.component';
import { DigitalMarketingWindsorComponent } from './digital-marketing-windsor/digital-marketing-windsor.component';
import { DigitalMarketingWishartComponent } from './digital-marketing-wishart/digital-marketing-wishart.component';
import { DigitalMarketingWoolloongabbaComponent } from './digital-marketing-woolloongabba/digital-marketing-woolloongabba.component';
import { DigitalMarketingWooloowinComponent } from './digital-marketing-wooloowin/digital-marketing-wooloowin.component';
import { DigitalMarketingWynnumWestComponent } from './digital-marketing-wynnum-west/digital-marketing-wynnum-west.component';
import { DigitalMarketingWynnumComponent } from './digital-marketing-wynnum/digital-marketing-wynnum.component';
import { DigitalMarketingYeerongpillyComponent } from './digital-marketing-yeerongpilly/digital-marketing-yeerongpilly.component';
import { DigitalMarketingYerongaComponent } from './digital-marketing-yeronga/digital-marketing-yeronga.component';
import { DigitalMarketingZillmereComponent } from './digital-marketing-zillmere/digital-marketing-zillmere.component';



import { IndexOnepageComponent } from './index-onepage/index-onepage.component';
import { ThankYouComponent } from './thank-you/thank-you.component';

const routes: Routes = [
  { path: '', component: IndexOnepageComponent },
  { path: 'thank-you', component: ThankYouComponent },
  { path: 'digital-marketing-bardon', component: DigitalMarketingBardonComponent },
  { path: 'digital-marketing-bowen-hills', component: DigitalMarketingBowenHillsComponent },
  { path: 'digital-marketing-brisbane', component: DigitalMarketingBrisbaneComponent },
  { path: 'digital-marketing-east-brisbane', component: DigitalMarketingEastBrisbaneComponent },
  { path: 'digital-marketing-fortitude-valley', component: DigitalMarketingFortitudeValleyComponent },
  { path: 'digital-marketing-herston', component: DigitalMarketingHerstonComponent },
  { path: 'digital-marketing-highgate-hill', component: DigitalMarketingHighgateHillComponent },
  { path: 'digital-marketing-kangaroo-point', component: DigitalMarketingKangarooPointComponent },
  { path: 'digital-marketing-kelvin-grove', component: DigitalMarketingKelvinGroveComponent },
  { path: 'digital-marketing-new-farm', component: DigitalMarketingNewFarmComponent },
  { path: 'digital-marketing-newstead', component: DigitalMarketingNewsteadComponent },
  { path: 'digital-marketing-paddington', component: DigitalMarketingPaddingtonComponent },
  { path: 'digital-marketing-petrie-terrace', component: DigitalMarketingPetrieTerraceComponent },
  { path: 'digital-marketing-red-hill', component: DigitalMarketingRedHillComponent },
  { path: 'digital-marketing-south-brisbane', component: DigitalMarketingSouthBrisbaneComponent },
  { path: 'digital-marketing-spring-hill', component: DigitalMarketingSpringHillComponent },
  { path: 'digital-marketing-teneriffe', component: DigitalMarketingTeneriffeComponent },
  { path: 'digital-marketing-west-end', component: DigitalMarketingWestEndComponent },
  { path: 'digital-marketing-woolloongabba', component: DigitalMarketingWoolloongabbaComponent },
  { path: 'digital-marketing-albion', component: DigitalMarketingAlbionComponent },
  { path: 'digital-marketing-alderley', component: DigitalMarketingAlderleyComponent },
  { path: 'digital-marketing-ascot', component: DigitalMarketingAscotComponent },
  { path: 'digital-marketing-aspley', component: DigitalMarketingAspleyComponent },
  { path: 'digital-marketing-bald-hills', component: DigitalMarketingBaldHillsComponent },
  { path: 'digital-marketing-banyo', component: DigitalMarketingBanyoComponent },
  { path: 'digital-marketing-boondall', component: DigitalMarketingBoondallComponent },
  { path: 'digital-marketing-bracken-ridge', component: DigitalMarketingBrackenRidgeComponent },
  { path: 'digital-marketing-bridgeman-downs', component: DigitalMarketingBridgemanDownsComponent },
  { path: 'digital-marketing-brighton', component: DigitalMarketingBrightonComponent },
  { path: 'digital-marketing-brisbane-airport', component: DigitalMarketingBrisbaneAirportComponent },
  { path: 'digital-marketing-carseldine', component: DigitalMarketingCarseldineComponent },
  { path: 'digital-marketing-chermside', component: DigitalMarketingChermsideComponent },
  { path: 'digital-marketing-chermside-west', component: DigitalMarketingChermsideWestComponent },
  { path: 'digital-marketing-clayfield', component: DigitalMarketingClayfieldComponent },
  { path: 'digital-marketing-deagon', component: DigitalMarketingDeagonComponent },
  { path: 'digital-marketing-eagle-farm', component: DigitalMarketingEagleFarmComponent },
  { path: 'digital-marketing-everton-park', component: DigitalMarketingEvertonParkComponent },
  { path: 'digital-marketing-fitzgibbon', component: DigitalMarketingFitzgibbonComponent },
  { path: 'digital-marketing-gaythorne', component: DigitalMarketingGaythorneComponent },
  { path: 'digital-marketing-geebung', component: DigitalMarketingGeebungComponent },
  { path: 'digital-marketing-gordon-park', component: DigitalMarketingGordonParkComponent },
  { path: 'digital-marketing-grange', component: DigitalMarketingGrangeComponent },
  { path: 'digital-marketing-hamilton', component: DigitalMarketingHamiltonComponent },
  { path: 'digital-marketing-hendra', component: DigitalMarketingHendraComponent },
  { path: 'digital-marketing-kedron', component: DigitalMarketingKedronComponent },
  { path: 'digital-marketing-keperra', component: DigitalMarketingKeperraComponent },
  { path: 'digital-marketing-lutwyche', component: DigitalMarketingLutwycheComponent },
  { path: 'digital-marketing-mcdowall', component: DigitalMarketingMcdowallComponent },
  { path: 'digital-marketing-mitchelton', component: DigitalMarketingMitcheltonComponent },
  { path: 'digital-marketing-myrtletown', component: DigitalMarketingMyrtletownComponent },
  { path: 'digital-marketing-newmarket', component: DigitalMarketingNewmarketComponent },
  { path: 'digital-marketing-northgate', component: DigitalMarketingNorthgateComponent },
  { path: 'digital-marketing-nudgee', component: DigitalMarketingNudgeeComponent },
  { path: 'digital-marketing-nudgee-beach', component: DigitalMarketingNudgeeBeachComponent },
  { path: 'digital-marketing-nundah', component: DigitalMarketingNundahComponent },
  { path: 'digital-marketing-pinkenba', component: DigitalMarketingPinkenbaComponent },
  { path: 'digital-marketing-sandgate', component: DigitalMarketingSandgateComponent },
  { path: 'digital-marketing-shorncliffe', component: DigitalMarketingShorncliffeComponent },
  { path: 'digital-marketing-stafford', component: DigitalMarketingStaffordComponent },
  { path: 'digital-marketing-stafford-heights', component: DigitalMarketingStaffordHeightsComponent },
  { path: 'digital-marketing-taigum', component: DigitalMarketingTaigumComponent },
  { path: 'digital-marketing-virginia', component: DigitalMarketingVirginiaComponent },
  { path: 'digital-marketing-wavell-heights', component: DigitalMarketingWavellHeightsComponent },
  { path: 'digital-marketing-wilston', component: DigitalMarketingWilstonComponent },
  { path: 'digital-marketing-windsor', component: DigitalMarketingWindsorComponent },
  { path: 'digital-marketing-wooloowin', component: DigitalMarketingWooloowinComponent },
  { path: 'digital-marketing-zillmere', component: DigitalMarketingZillmereComponent },
  { path: 'digital-marketing-acacia-ridge', component: DigitalMarketingAcaciaRidgeComponent },
  { path: 'digital-marketing-algester', component: DigitalMarketingAlgesterComponent },
  { path: 'digital-marketing-annerley', component: DigitalMarketingAnnerleyComponent },
  { path: 'digital-marketing-archerfield', component: DigitalMarketingArcherfieldComponent },
  { path: 'digital-marketing-burbank', component: DigitalMarketingBurbankComponent },
  { path: 'digital-marketing-calamvale', component: DigitalMarketingCalamvaleComponent },
  { path: 'digital-marketing-coopers-plains', component: DigitalMarketingCoopersPlainsComponent },
  { path: 'digital-marketing-darra', component: DigitalMarketingDarraComponent },
  { path: 'digital-marketing-doolandella', component: DigitalMarketingDoolandellaComponent },
  { path: 'digital-marketing-drewvale', component: DigitalMarketingDrewvaleComponent },
  { path: 'digital-marketing-durack', component: DigitalMarketingDurackComponent },
  { path: 'digital-marketing-dutton-park', component: DigitalMarketingDuttonParkComponent },
  { path: 'digital-marketing-eight-mile-plains', component: DigitalMarketingEightMilePlainsComponent },
  { path: 'digital-marketing-ellen-grove', component: DigitalMarketingEllenGroveComponent },
  { path: 'digital-marketing-fairfield', component: DigitalMarketingFairfieldComponent },
  { path: 'digital-marketing-forest-lake', component: DigitalMarketingForestLakeComponent },
  { path: 'digital-marketing-greenslopes', component: DigitalMarketingGreenslopesComponent },
  { path: 'digital-marketing-heathwood', component: DigitalMarketingHeathwoodComponent },
  { path: 'digital-marketing-holland-park', component: DigitalMarketingHollandParkComponent },
  { path: 'digital-marketing-holland-park-west', component: DigitalMarketingHollandParkWestComponent },
  { path: 'digital-marketing-inala', component: DigitalMarketingInalaComponent },
  { path: 'digital-marketing-karawatha', component: DigitalMarketingKarawathaComponent },
  { path: 'digital-marketing-kuraby', component: DigitalMarketingKurabyComponent },
  { path: 'digital-marketing-larapinta', component: DigitalMarketingLarapintaComponent },
  { path: 'digital-marketing-macgregor', component: DigitalMarketingMacgregorComponent },
  { path: 'digital-marketing-mackenzie', component: DigitalMarketingMackenzieComponent },
  { path: 'digital-marketing-mansfield', component: DigitalMarketingMansfieldComponent },
  { path: 'digital-marketing-moorooka', component: DigitalMarketingMoorookaComponent },
  { path: 'digital-marketing-mount-gravatt', component: DigitalMarketingMountGravattComponent },
  { path: 'digital-marketing-mount-gravatt-east', component: DigitalMarketingMountGravattEastComponent },
  { path: 'digital-marketing-nathan', component: DigitalMarketingNathanComponent },
  { path: 'digital-marketing-pallara', component: DigitalMarketingPallaraComponent },
  { path: 'digital-marketing-parkinson', component: DigitalMarketingParkinsonComponent },
  { path: 'digital-marketing-richlands', component: DigitalMarketingRichlandsComponent },
  { path: 'digital-marketing-robertson', component: DigitalMarketingRobertsonComponent },
  { path: 'digital-marketing-rochedale', component: DigitalMarketingRochedaleComponent },
  { path: 'digital-marketing-rocklea', component: DigitalMarketingRockleaComponent },
  { path: 'digital-marketing-runcorn', component: DigitalMarketingRuncornComponent },
  { path: 'digital-marketing-salisbury', component: DigitalMarketingSalisburyComponent },
  { path: 'digital-marketing-seventeen-mile-rocks', component: DigitalMarketingSeventeenMileRocksComponent },
  { path: 'digital-marketing-sinnamon-park', component: DigitalMarketingSinnamonParkComponent },
  { path: 'digital-marketing-stretton', component: DigitalMarketingStrettonComponent },
  { path: 'digital-marketing-sumner', component: DigitalMarketingSumnerComponent },
  { path: 'digital-marketing-sunnybank', component: DigitalMarketingSunnybankComponent },
  { path: 'digital-marketing-sunnybank-hills', component: DigitalMarketingSunnybankHillsComponent },
  { path: 'digital-marketing-tarragindi', component: DigitalMarketingTarragindiComponent },
  { path: 'digital-marketing-tennyson', component: DigitalMarketingTennysonComponent },
  { path: 'digital-marketing-upper-mount-gravatt', component: DigitalMarketingUpperMountGravattComponent },
  { path: 'digital-marketing-wacol', component: DigitalMarketingWacolComponent },
  { path: 'digital-marketing-willawong', component: DigitalMarketingWillawongComponent },
  { path: 'digital-marketing-wishart', component: DigitalMarketingWishartComponent },
  { path: 'digital-marketing-yeerongpilly', component: DigitalMarketingYeerongpillyComponent },
  { path: 'digital-marketing-yeronga', component: DigitalMarketingYerongaComponent },
  { path: 'digital-marketing-balmoral', component: DigitalMarketingBalmoralComponent },
  { path: 'digital-marketing-belmont', component: DigitalMarketingBelmontComponent },
  { path: 'digital-marketing-bulimba', component: DigitalMarketingBulimbaComponent },
  { path: 'digital-marketing-camp-hill', component: DigitalMarketingCampHillComponent },
  { path: 'digital-marketing-cannon-hill', component: DigitalMarketingCannonHillComponent },
  { path: 'digital-marketing-carina', component: DigitalMarketingCarinaComponent },
  { path: 'digital-marketing-carina-heights', component: DigitalMarketingCarinaHeightsComponent },
  { path: 'digital-marketing-carindale', component: DigitalMarketingCarindaleComponent },
  { path: 'digital-marketing-chandler', component: DigitalMarketingChandlerComponent },
  { path: 'digital-marketing-coorparoo', component: DigitalMarketingCoorparooComponent },
  { path: 'digital-marketing-gumdale', component: DigitalMarketingGumdaleComponent },
  { path: 'digital-marketing-hawthorne', component: DigitalMarketingHawthorneComponent },
  { path: 'digital-marketing-hemmant', component: DigitalMarketingHemmantComponent },
  { path: 'digital-marketing-lota', component: DigitalMarketingLotaComponent },
  { path: 'digital-marketing-lytton', component: DigitalMarketingLyttonComponent },
  { path: 'digital-marketing-manly', component: DigitalMarketingManlyComponent },
  { path: 'digital-marketing-manly-west', component: DigitalMarketingManlyWestComponent },
  { path: 'digital-marketing-moreton-island', component: DigitalMarketingMoretonIslandComponent },
  { path: 'digital-marketing-morningside', component: DigitalMarketingMorningsideComponent },
  { path: 'digital-marketing-murarrie', component: DigitalMarketingMurarrieComponent },
  { path: 'digital-marketing-norman-park', component: DigitalMarketingNormanParkComponent },
  { path: 'digital-marketing-port-of-brisbane', component: DigitalMarketingPortOfBrisbaneComponent },
  { path: 'digital-marketing-ransome', component: DigitalMarketingRansomeComponent },
  { path: 'digital-marketing-seven-hills', component: DigitalMarketingSevenHillsComponent },
  { path: 'digital-marketing-tingalpa', component: DigitalMarketingTingalpaComponent },
  { path: 'digital-marketing-wakerley', component: DigitalMarketingWakerleyComponent },
  { path: 'digital-marketing-wynnum', component: DigitalMarketingWynnumComponent },
  { path: 'digital-marketing-wynnum-west', component: DigitalMarketingWynnumWestComponent },
  { path: 'digital-marketing-anstead', component: DigitalMarketingAnsteadComponent },
  { path: 'digital-marketing-ashgrove', component: DigitalMarketingAshgroveComponent },
  { path: 'digital-marketing-auchenflower', component: DigitalMarketingAuchenflowerComponent },
  { path: 'digital-marketing-banks-creek', component: DigitalMarketingBanksCreekComponent },
  { path: 'digital-marketing-bellbowrie', component: DigitalMarketingBellbowrieComponent },
  { path: 'digital-marketing-brookfield', component: DigitalMarketingBrookfieldComponent },
  { path: 'digital-marketing-chapel-hill', component: DigitalMarketingChapelHillComponent },
  { path: 'digital-marketing-chelmer', component: DigitalMarketingChelmerComponent },
  { path: 'digital-marketing-chuwar', component: DigitalMarketingChuwarComponent },
  { path: 'digital-marketing-corinda', component: DigitalMarketingCorindaComponent },
  { path: 'digital-marketing-england-creek', component: DigitalMarketingEnglandCreekComponent },
  { path: 'digital-marketing-enoggera', component: DigitalMarketingEnoggeraComponent },
  { path: 'digital-marketing-enoggera-reservoir', component: DigitalMarketingEnoggeraReservoirComponent },
  { path: 'digital-marketing-ferny-grove', component: DigitalMarketingFernyGroveComponent },
  { path: 'digital-marketing-fig-tree-pocket', component: DigitalMarketingFigTreePocketComponent },
  { path: 'digital-marketing-graceville', component: DigitalMarketingGracevilleComponent },
  { path: 'digital-marketing-indooroopilly', component: DigitalMarketingIndooroopillyComponent },
  { path: 'digital-marketing-jamboree-heights', component: DigitalMarketingJamboreeHeightsComponent },
  { path: 'digital-marketing-jindalee', component: DigitalMarketingJindaleeComponent },
  { path: 'digital-marketing-karana-downs', component: DigitalMarketingKaranaDownsComponent },
  { path: 'digital-marketing-kenmore', component: DigitalMarketingKenmoreComponent },
  { path: 'digital-marketing-kenmore-hills', component: DigitalMarketingKenmoreHillsComponent },
  { path: 'digital-marketing-kholo', component: DigitalMarketingKholoComponent },
  { path: 'digital-marketing-lake-manchester', component: DigitalMarketingLakeManchesterComponent },
  { path: 'digital-marketing-middle-park', component: DigitalMarketingMiddleParkComponent },
  { path: 'digital-marketing-milton', component: DigitalMarketingMiltonComponent },
  { path: 'digital-marketing-moggill', component: DigitalMarketingMoggillComponent },
  { path: 'digital-marketing-mount-coot-tha', component: DigitalMarketingMountCootThaComponent },
  { path: 'digital-marketing-mount-crosby', component: DigitalMarketingMountCrosbyComponent },
  { path: 'digital-marketing-mount-ommaney', component: DigitalMarketingMountOmmaneyComponent },
  { path: 'digital-marketing-oxley', component: DigitalMarketingOxleyComponent },
  { path: 'digital-marketing-pinjarra-hills', component: DigitalMarketingPinjarraHillsComponent },
  { path: 'digital-marketing-pullenvale', component: DigitalMarketingPullenvaleComponent },
  { path: 'digital-marketing-riverhills', component: DigitalMarketingRiverhillsComponent },
  { path: 'digital-marketing-sherwood', component: DigitalMarketingSherwoodComponent },
  { path: 'digital-marketing-sinnamon-park', component: DigitalMarketingSinnamonParkComponent },
  { path: 'digital-marketing-st-lucia', component: DigitalMarketingStLuciaComponent },
  { path: 'digital-marketing-taringa', component: DigitalMarketingTaringaComponent },
  { path: 'digital-marketing-the-gap', component: DigitalMarketingTheGapComponent },
  { path: 'digital-marketing-toowong', component: DigitalMarketingToowongComponent },
  { path: 'digital-marketing-upper-brookfield', component: DigitalMarketingUpperBrookfieldComponent },
  { path: 'digital-marketing-upper-kedron', component: DigitalMarketingUpperKedronComponent },
  { path: 'digital-marketing-westlake', component: DigitalMarketingWestlakeComponent },
  { path: 'digital-marketing-bulwer', component: DigitalMarketingBulwerComponent },
  { path: 'digital-marketing-cowan-cowan', component: DigitalMarketingCowanCowanComponent },
  { path: 'digital-marketing-kooringal', component: DigitalMarketingKooringalComponent },
  { path: 'digital-marketing-moreton-bay', component: DigitalMarketingMoretonBayComponent },
  { path: 'digital-marketing-moreton-island', component: DigitalMarketingMoretonIslandComponent }  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled",
  scrollOffset: [0, 0],
  // Enable scrolling to anchors
  anchorScrolling: "enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
