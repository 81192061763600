import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-marketing-archerfield',
  templateUrl: './digital-marketing-archerfield.component.html',
  styleUrls: ['./digital-marketing-archerfield.component.css']
})
export class DigitalMarketingArcherfieldComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
