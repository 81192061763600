import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';

import { SharedModule } from "./shared/shared.module";
import { IndexOnepageComponent } from './index-onepage/index-onepage.component';
import { Meta } from '@angular/platform-browser';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { ScullyLibModule } from '@scullyio/ng-lib';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DigitalMarketingBardonComponent } from './digital-marketing-bardon/digital-marketing-bardon.component';
import { DigitalMarketingBowenHillsComponent } from './digital-marketing-bowen-hills/digital-marketing-bowen-hills.component';
import { DigitalMarketingBrisbaneComponent } from './digital-marketing-brisbane/digital-marketing-brisbane.component';
import { DigitalMarketingEastBrisbaneComponent } from './digital-marketing-east-brisbane/digital-marketing-east-brisbane.component';
import { DigitalMarketingFortitudeValleyComponent } from './digital-marketing-fortitude-valley/digital-marketing-fortitude-valley.component';
import { DigitalMarketingHerstonComponent } from './digital-marketing-herston/digital-marketing-herston.component';
import { DigitalMarketingHighgateHillComponent } from './digital-marketing-highgate-hill/digital-marketing-highgate-hill.component';
import { DigitalMarketingKangarooPointComponent } from './digital-marketing-kangaroo-point/digital-marketing-kangaroo-point.component';
import { DigitalMarketingKelvinGroveComponent } from './digital-marketing-kelvin-grove/digital-marketing-kelvin-grove.component';
import { DigitalMarketingNewFarmComponent } from './digital-marketing-new-farm/digital-marketing-new-farm.component';
import { DigitalMarketingNewsteadComponent } from './digital-marketing-newstead/digital-marketing-newstead.component';
import { DigitalMarketingPaddingtonComponent } from './digital-marketing-paddington/digital-marketing-paddington.component';
import { DigitalMarketingPetrieTerraceComponent } from './digital-marketing-petrie-terrace/digital-marketing-petrie-terrace.component';
import { DigitalMarketingRedHillComponent } from './digital-marketing-red-hill/digital-marketing-red-hill.component';
import { DigitalMarketingSouthBrisbaneComponent } from './digital-marketing-south-brisbane/digital-marketing-south-brisbane.component';
import { DigitalMarketingSpringHillComponent } from './digital-marketing-spring-hill/digital-marketing-spring-hill.component';
import { DigitalMarketingTeneriffeComponent } from './digital-marketing-teneriffe/digital-marketing-teneriffe.component';
import { DigitalMarketingWestEndComponent } from './digital-marketing-west-end/digital-marketing-west-end.component';
import { DigitalMarketingWoolloongabbaComponent } from './digital-marketing-woolloongabba/digital-marketing-woolloongabba.component';
import { DigitalMarketingAlbionComponent } from './digital-marketing-albion/digital-marketing-albion.component';
import { DigitalMarketingAlderleyComponent } from './digital-marketing-alderley/digital-marketing-alderley.component';
import { DigitalMarketingAscotComponent } from './digital-marketing-ascot/digital-marketing-ascot.component';
import { DigitalMarketingAspleyComponent } from './digital-marketing-aspley/digital-marketing-aspley.component';
import { DigitalMarketingBaldHillsComponent } from './digital-marketing-bald-hills/digital-marketing-bald-hills.component';
import { DigitalMarketingBanyoComponent } from './digital-marketing-banyo/digital-marketing-banyo.component';
import { DigitalMarketingBoondallComponent } from './digital-marketing-boondall/digital-marketing-boondall.component';
import { DigitalMarketingBrackenRidgeComponent } from './digital-marketing-bracken-ridge/digital-marketing-bracken-ridge.component';
import { DigitalMarketingBridgemanDownsComponent } from './digital-marketing-bridgeman-downs/digital-marketing-bridgeman-downs.component';
import { DigitalMarketingBrightonComponent } from './digital-marketing-brighton/digital-marketing-brighton.component';
import { DigitalMarketingBrisbaneAirportComponent } from './digital-marketing-brisbane-airport/digital-marketing-brisbane-airport.component';
import { DigitalMarketingCarseldineComponent } from './digital-marketing-carseldine/digital-marketing-carseldine.component';
import { DigitalMarketingChermsideComponent } from './digital-marketing-chermside/digital-marketing-chermside.component';
import { DigitalMarketingChermsideWestComponent } from './digital-marketing-chermside-west/digital-marketing-chermside-west.component';
import { DigitalMarketingClayfieldComponent } from './digital-marketing-clayfield/digital-marketing-clayfield.component';
import { DigitalMarketingDeagonComponent } from './digital-marketing-deagon/digital-marketing-deagon.component';
import { DigitalMarketingEagleFarmComponent } from './digital-marketing-eagle-farm/digital-marketing-eagle-farm.component';
import { DigitalMarketingEvertonParkComponent } from './digital-marketing-everton-park/digital-marketing-everton-park.component';
import { DigitalMarketingFitzgibbonComponent } from './digital-marketing-fitzgibbon/digital-marketing-fitzgibbon.component';
import { DigitalMarketingGaythorneComponent } from './digital-marketing-gaythorne/digital-marketing-gaythorne.component';
import { DigitalMarketingGeebungComponent } from './digital-marketing-geebung/digital-marketing-geebung.component';
import { DigitalMarketingGordonParkComponent } from './digital-marketing-gordon-park/digital-marketing-gordon-park.component';
import { DigitalMarketingGrangeComponent } from './digital-marketing-grange/digital-marketing-grange.component';
import { DigitalMarketingHamiltonComponent } from './digital-marketing-hamilton/digital-marketing-hamilton.component';
import { DigitalMarketingHendraComponent } from './digital-marketing-hendra/digital-marketing-hendra.component';
import { DigitalMarketingKedronComponent } from './digital-marketing-kedron/digital-marketing-kedron.component';
import { DigitalMarketingKeperraComponent } from './digital-marketing-keperra/digital-marketing-keperra.component';
import { DigitalMarketingLutwycheComponent } from './digital-marketing-lutwyche/digital-marketing-lutwyche.component';
import { DigitalMarketingMcdowallComponent } from './digital-marketing-mcdowall/digital-marketing-mcdowall.component';
import { DigitalMarketingMitcheltonComponent } from './digital-marketing-mitchelton/digital-marketing-mitchelton.component';
import { DigitalMarketingMyrtletownComponent } from './digital-marketing-myrtletown/digital-marketing-myrtletown.component';
import { DigitalMarketingNewmarketComponent } from './digital-marketing-newmarket/digital-marketing-newmarket.component';
import { DigitalMarketingNorthgateComponent } from './digital-marketing-northgate/digital-marketing-northgate.component';
import { DigitalMarketingNudgeeComponent } from './digital-marketing-nudgee/digital-marketing-nudgee.component';
import { DigitalMarketingNudgeeBeachComponent } from './digital-marketing-nudgee-beach/digital-marketing-nudgee-beach.component';
import { DigitalMarketingNundahComponent } from './digital-marketing-nundah/digital-marketing-nundah.component';
import { DigitalMarketingPinkenbaComponent } from './digital-marketing-pinkenba/digital-marketing-pinkenba.component';
import { DigitalMarketingSandgateComponent } from './digital-marketing-sandgate/digital-marketing-sandgate.component';
import { DigitalMarketingShorncliffeComponent } from './digital-marketing-shorncliffe/digital-marketing-shorncliffe.component';
import { DigitalMarketingStaffordComponent } from './digital-marketing-stafford/digital-marketing-stafford.component';
import { DigitalMarketingStaffordHeightsComponent } from './digital-marketing-stafford-heights/digital-marketing-stafford-heights.component';
import { DigitalMarketingTaigumComponent } from './digital-marketing-taigum/digital-marketing-taigum.component';
import { DigitalMarketingVirginiaComponent } from './digital-marketing-virginia/digital-marketing-virginia.component';
import { DigitalMarketingWavellHeightsComponent } from './digital-marketing-wavell-heights/digital-marketing-wavell-heights.component';
import { DigitalMarketingWilstonComponent } from './digital-marketing-wilston/digital-marketing-wilston.component';
import { DigitalMarketingWindsorComponent } from './digital-marketing-windsor/digital-marketing-windsor.component';
import { DigitalMarketingWooloowinComponent } from './digital-marketing-wooloowin/digital-marketing-wooloowin.component';
import { DigitalMarketingZillmereComponent } from './digital-marketing-zillmere/digital-marketing-zillmere.component';
import { DigitalMarketingAcaciaRidgeComponent } from './digital-marketing-acacia-ridge/digital-marketing-acacia-ridge.component';
import { DigitalMarketingAlgesterComponent } from './digital-marketing-algester/digital-marketing-algester.component';
import { DigitalMarketingAnnerleyComponent } from './digital-marketing-annerley/digital-marketing-annerley.component';
import { DigitalMarketingArcherfieldComponent } from './digital-marketing-archerfield/digital-marketing-archerfield.component';
import { DigitalMarketingBurbankComponent } from './digital-marketing-burbank/digital-marketing-burbank.component';
import { DigitalMarketingCalamvaleComponent } from './digital-marketing-calamvale/digital-marketing-calamvale.component';
import { DigitalMarketingCoopersPlainsComponent } from './digital-marketing-coopers-plains/digital-marketing-coopers-plains.component';
import { DigitalMarketingDarraComponent } from './digital-marketing-darra/digital-marketing-darra.component';
import { DigitalMarketingDoolandellaComponent } from './digital-marketing-doolandella/digital-marketing-doolandella.component';
import { DigitalMarketingDrewvaleComponent } from './digital-marketing-drewvale/digital-marketing-drewvale.component';
import { DigitalMarketingDurackComponent } from './digital-marketing-durack/digital-marketing-durack.component';
import { DigitalMarketingDuttonParkComponent } from './digital-marketing-dutton-park/digital-marketing-dutton-park.component';
import { DigitalMarketingEightMilePlainsComponent } from './digital-marketing-eight-mile-plains/digital-marketing-eight-mile-plains.component';
import { DigitalMarketingEllenGroveComponent } from './digital-marketing-ellen-grove/digital-marketing-ellen-grove.component';
import { DigitalMarketingFairfieldComponent } from './digital-marketing-fairfield/digital-marketing-fairfield.component';
import { DigitalMarketingForestLakeComponent } from './digital-marketing-forest-lake/digital-marketing-forest-lake.component';
import { DigitalMarketingGreenslopesComponent } from './digital-marketing-greenslopes/digital-marketing-greenslopes.component';
import { DigitalMarketingHeathwoodComponent } from './digital-marketing-heathwood/digital-marketing-heathwood.component';
import { DigitalMarketingHollandParkComponent } from './digital-marketing-holland-park/digital-marketing-holland-park.component';
import { DigitalMarketingHollandParkWestComponent } from './digital-marketing-holland-park-west/digital-marketing-holland-park-west.component';
import { DigitalMarketingInalaComponent } from './digital-marketing-inala/digital-marketing-inala.component';
import { DigitalMarketingKarawathaComponent } from './digital-marketing-karawatha/digital-marketing-karawatha.component';
import { DigitalMarketingKurabyComponent } from './digital-marketing-kuraby/digital-marketing-kuraby.component';
import { DigitalMarketingLarapintaComponent } from './digital-marketing-larapinta/digital-marketing-larapinta.component';
import { DigitalMarketingMacgregorComponent } from './digital-marketing-macgregor/digital-marketing-macgregor.component';
import { DigitalMarketingMackenzieComponent } from './digital-marketing-mackenzie/digital-marketing-mackenzie.component';
import { DigitalMarketingMansfieldComponent } from './digital-marketing-mansfield/digital-marketing-mansfield.component';
import { DigitalMarketingMoorookaComponent } from './digital-marketing-moorooka/digital-marketing-moorooka.component';
import { DigitalMarketingMountGravattComponent } from './digital-marketing-mount-gravatt/digital-marketing-mount-gravatt.component';
import { DigitalMarketingMountGravattEastComponent } from './digital-marketing-mount-gravatt-east/digital-marketing-mount-gravatt-east.component';
import { DigitalMarketingNathanComponent } from './digital-marketing-nathan/digital-marketing-nathan.component';
import { DigitalMarketingPallaraComponent } from './digital-marketing-pallara/digital-marketing-pallara.component';
import { DigitalMarketingParkinsonComponent } from './digital-marketing-parkinson/digital-marketing-parkinson.component';
import { DigitalMarketingRichlandsComponent } from './digital-marketing-richlands/digital-marketing-richlands.component';
import { DigitalMarketingRobertsonComponent } from './digital-marketing-robertson/digital-marketing-robertson.component';
import { DigitalMarketingRochedaleComponent } from './digital-marketing-rochedale/digital-marketing-rochedale.component';
import { DigitalMarketingRockleaComponent } from './digital-marketing-rocklea/digital-marketing-rocklea.component';
import { DigitalMarketingRuncornComponent } from './digital-marketing-runcorn/digital-marketing-runcorn.component';
import { DigitalMarketingSalisburyComponent } from './digital-marketing-salisbury/digital-marketing-salisbury.component';
import { DigitalMarketingSeventeenMileRocksComponent } from './digital-marketing-seventeen-mile-rocks/digital-marketing-seventeen-mile-rocks.component';
import { DigitalMarketingSinnamonParkComponent } from './digital-marketing-sinnamon-park/digital-marketing-sinnamon-park.component';
import { DigitalMarketingStrettonComponent } from './digital-marketing-stretton/digital-marketing-stretton.component';
import { DigitalMarketingSumnerComponent } from './digital-marketing-sumner/digital-marketing-sumner.component';
import { DigitalMarketingSunnybankComponent } from './digital-marketing-sunnybank/digital-marketing-sunnybank.component';
import { DigitalMarketingSunnybankHillsComponent } from './digital-marketing-sunnybank-hills/digital-marketing-sunnybank-hills.component';
import { DigitalMarketingTarragindiComponent } from './digital-marketing-tarragindi/digital-marketing-tarragindi.component';
import { DigitalMarketingTennysonComponent } from './digital-marketing-tennyson/digital-marketing-tennyson.component';
import { DigitalMarketingUpperMountGravattComponent } from './digital-marketing-upper-mount-gravatt/digital-marketing-upper-mount-gravatt.component';
import { DigitalMarketingWacolComponent } from './digital-marketing-wacol/digital-marketing-wacol.component';
import { DigitalMarketingWillawongComponent } from './digital-marketing-willawong/digital-marketing-willawong.component';
import { DigitalMarketingWishartComponent } from './digital-marketing-wishart/digital-marketing-wishart.component';
import { DigitalMarketingYeerongpillyComponent } from './digital-marketing-yeerongpilly/digital-marketing-yeerongpilly.component';
import { DigitalMarketingYerongaComponent } from './digital-marketing-yeronga/digital-marketing-yeronga.component';
import { DigitalMarketingBalmoralComponent } from './digital-marketing-balmoral/digital-marketing-balmoral.component';
import { DigitalMarketingBelmontComponent } from './digital-marketing-belmont/digital-marketing-belmont.component';
import { DigitalMarketingBulimbaComponent } from './digital-marketing-bulimba/digital-marketing-bulimba.component';
import { DigitalMarketingCampHillComponent } from './digital-marketing-camp-hill/digital-marketing-camp-hill.component';
import { DigitalMarketingCannonHillComponent } from './digital-marketing-cannon-hill/digital-marketing-cannon-hill.component';
import { DigitalMarketingCarinaComponent } from './digital-marketing-carina/digital-marketing-carina.component';
import { DigitalMarketingCarinaHeightsComponent } from './digital-marketing-carina-heights/digital-marketing-carina-heights.component';
import { DigitalMarketingCarindaleComponent } from './digital-marketing-carindale/digital-marketing-carindale.component';
import { DigitalMarketingChandlerComponent } from './digital-marketing-chandler/digital-marketing-chandler.component';
import { DigitalMarketingCoorparooComponent } from './digital-marketing-coorparoo/digital-marketing-coorparoo.component';
import { DigitalMarketingGumdaleComponent } from './digital-marketing-gumdale/digital-marketing-gumdale.component';
import { DigitalMarketingHawthorneComponent } from './digital-marketing-hawthorne/digital-marketing-hawthorne.component';
import { DigitalMarketingHemmantComponent } from './digital-marketing-hemmant/digital-marketing-hemmant.component';
import { DigitalMarketingLotaComponent } from './digital-marketing-lota/digital-marketing-lota.component';
import { DigitalMarketingLyttonComponent } from './digital-marketing-lytton/digital-marketing-lytton.component';
import { DigitalMarketingManlyComponent } from './digital-marketing-manly/digital-marketing-manly.component';
import { DigitalMarketingManlyWestComponent } from './digital-marketing-manly-west/digital-marketing-manly-west.component';
import { DigitalMarketingMoretonIslandComponent } from './digital-marketing-moreton-island/digital-marketing-moreton-island.component';
import { DigitalMarketingMorningsideComponent } from './digital-marketing-morningside/digital-marketing-morningside.component';
import { DigitalMarketingMurarrieComponent } from './digital-marketing-murarrie/digital-marketing-murarrie.component';
import { DigitalMarketingNormanParkComponent } from './digital-marketing-norman-park/digital-marketing-norman-park.component';
import { DigitalMarketingPortOfBrisbaneComponent } from './digital-marketing-port-of-brisbane/digital-marketing-port-of-brisbane.component';
import { DigitalMarketingRansomeComponent } from './digital-marketing-ransome/digital-marketing-ransome.component';
import { DigitalMarketingSevenHillsComponent } from './digital-marketing-seven-hills/digital-marketing-seven-hills.component';
import { DigitalMarketingTingalpaComponent } from './digital-marketing-tingalpa/digital-marketing-tingalpa.component';
import { DigitalMarketingWakerleyComponent } from './digital-marketing-wakerley/digital-marketing-wakerley.component';
import { DigitalMarketingWynnumComponent } from './digital-marketing-wynnum/digital-marketing-wynnum.component';
import { DigitalMarketingWynnumWestComponent } from './digital-marketing-wynnum-west/digital-marketing-wynnum-west.component';
import { DigitalMarketingAnsteadComponent } from './digital-marketing-anstead/digital-marketing-anstead.component';
import { DigitalMarketingAshgroveComponent } from './digital-marketing-ashgrove/digital-marketing-ashgrove.component';
import { DigitalMarketingAuchenflowerComponent } from './digital-marketing-auchenflower/digital-marketing-auchenflower.component';
import { DigitalMarketingBanksCreekComponent } from './digital-marketing-banks-creek/digital-marketing-banks-creek.component';
import { DigitalMarketingBellbowrieComponent } from './digital-marketing-bellbowrie/digital-marketing-bellbowrie.component';
import { DigitalMarketingBrookfieldComponent } from './digital-marketing-brookfield/digital-marketing-brookfield.component';
import { DigitalMarketingChapelHillComponent } from './digital-marketing-chapel-hill/digital-marketing-chapel-hill.component';
import { DigitalMarketingChelmerComponent } from './digital-marketing-chelmer/digital-marketing-chelmer.component';
import { DigitalMarketingChuwarComponent } from './digital-marketing-chuwar/digital-marketing-chuwar.component';
import { DigitalMarketingCorindaComponent } from './digital-marketing-corinda/digital-marketing-corinda.component';
import { DigitalMarketingEnglandCreekComponent } from './digital-marketing-england-creek/digital-marketing-england-creek.component';
import { DigitalMarketingEnoggeraComponent } from './digital-marketing-enoggera/digital-marketing-enoggera.component';
import { DigitalMarketingEnoggeraReservoirComponent } from './digital-marketing-enoggera-reservoir/digital-marketing-enoggera-reservoir.component';
import { DigitalMarketingFernyGroveComponent } from './digital-marketing-ferny-grove/digital-marketing-ferny-grove.component';
import { DigitalMarketingFigTreePocketComponent } from './digital-marketing-fig-tree-pocket/digital-marketing-fig-tree-pocket.component';
import { DigitalMarketingGracevilleComponent } from './digital-marketing-graceville/digital-marketing-graceville.component';
import { DigitalMarketingIndooroopillyComponent } from './digital-marketing-indooroopilly/digital-marketing-indooroopilly.component';
import { DigitalMarketingJamboreeHeightsComponent } from './digital-marketing-jamboree-heights/digital-marketing-jamboree-heights.component';
import { DigitalMarketingJindaleeComponent } from './digital-marketing-jindalee/digital-marketing-jindalee.component';
import { DigitalMarketingKaranaDownsComponent } from './digital-marketing-karana-downs/digital-marketing-karana-downs.component';
import { DigitalMarketingKenmoreComponent } from './digital-marketing-kenmore/digital-marketing-kenmore.component';
import { DigitalMarketingKenmoreHillsComponent } from './digital-marketing-kenmore-hills/digital-marketing-kenmore-hills.component';
import { DigitalMarketingKholoComponent } from './digital-marketing-kholo/digital-marketing-kholo.component';
import { DigitalMarketingLakeManchesterComponent } from './digital-marketing-lake-manchester/digital-marketing-lake-manchester.component';
import { DigitalMarketingMiddleParkComponent } from './digital-marketing-middle-park/digital-marketing-middle-park.component';
import { DigitalMarketingMiltonComponent } from './digital-marketing-milton/digital-marketing-milton.component';
import { DigitalMarketingMoggillComponent } from './digital-marketing-moggill/digital-marketing-moggill.component';
import { DigitalMarketingMountCootThaComponent } from './digital-marketing-mount-coot-tha/digital-marketing-mount-coot-tha.component';
import { DigitalMarketingMountCrosbyComponent } from './digital-marketing-mount-crosby/digital-marketing-mount-crosby.component';
import { DigitalMarketingMountOmmaneyComponent } from './digital-marketing-mount-ommaney/digital-marketing-mount-ommaney.component';
import { DigitalMarketingOxleyComponent } from './digital-marketing-oxley/digital-marketing-oxley.component';
import { DigitalMarketingPinjarraHillsComponent } from './digital-marketing-pinjarra-hills/digital-marketing-pinjarra-hills.component';
import { DigitalMarketingPullenvaleComponent } from './digital-marketing-pullenvale/digital-marketing-pullenvale.component';
import { DigitalMarketingRiverhillsComponent } from './digital-marketing-riverhills/digital-marketing-riverhills.component';
import { DigitalMarketingSherwoodComponent } from './digital-marketing-sherwood/digital-marketing-sherwood.component';
import { DigitalMarketingStLuciaComponent } from './digital-marketing-st-lucia/digital-marketing-st-lucia.component';
import { DigitalMarketingTaringaComponent } from './digital-marketing-taringa/digital-marketing-taringa.component';
import { DigitalMarketingTheGapComponent } from './digital-marketing-the-gap/digital-marketing-the-gap.component';
import { DigitalMarketingToowongComponent } from './digital-marketing-toowong/digital-marketing-toowong.component';
import { DigitalMarketingUpperBrookfieldComponent } from './digital-marketing-upper-brookfield/digital-marketing-upper-brookfield.component';
import { DigitalMarketingUpperKedronComponent } from './digital-marketing-upper-kedron/digital-marketing-upper-kedron.component';
import { DigitalMarketingWestlakeComponent } from './digital-marketing-westlake/digital-marketing-westlake.component';
import { DigitalMarketingBulwerComponent } from './digital-marketing-bulwer/digital-marketing-bulwer.component';
import { DigitalMarketingCowanCowanComponent } from './digital-marketing-cowan-cowan/digital-marketing-cowan-cowan.component';
import { DigitalMarketingKooringalComponent } from './digital-marketing-kooringal/digital-marketing-kooringal.component';
import { DigitalMarketingMoretonBayComponent } from './digital-marketing-moreton-bay/digital-marketing-moreton-bay.component';
import { ThankYouComponent } from './thank-you/thank-you.component';

@NgModule({
  declarations: [
    AppComponent,
    IndexOnepageComponent,
    ThankYouComponent,
    DigitalMarketingBardonComponent,
    DigitalMarketingBowenHillsComponent,
    DigitalMarketingBrisbaneComponent,
    DigitalMarketingEastBrisbaneComponent,
    DigitalMarketingFortitudeValleyComponent,
    DigitalMarketingHerstonComponent,
    DigitalMarketingHighgateHillComponent,
    DigitalMarketingKangarooPointComponent,
    DigitalMarketingKelvinGroveComponent,
    DigitalMarketingNewFarmComponent,
    DigitalMarketingNewsteadComponent,
    DigitalMarketingPaddingtonComponent,
    DigitalMarketingPetrieTerraceComponent,
    DigitalMarketingRedHillComponent,
    DigitalMarketingSouthBrisbaneComponent,
    DigitalMarketingSpringHillComponent,
    DigitalMarketingTeneriffeComponent,
    DigitalMarketingWestEndComponent,
    DigitalMarketingWoolloongabbaComponent,
    DigitalMarketingAlbionComponent,
    DigitalMarketingAlderleyComponent,
    DigitalMarketingAscotComponent,
    DigitalMarketingAspleyComponent,
    DigitalMarketingBaldHillsComponent,
    DigitalMarketingBanyoComponent,
    DigitalMarketingBoondallComponent,
    DigitalMarketingBrackenRidgeComponent,
    DigitalMarketingBridgemanDownsComponent,
    DigitalMarketingBrightonComponent,
    DigitalMarketingBrisbaneAirportComponent,
    DigitalMarketingCarseldineComponent,
    DigitalMarketingChermsideComponent,
    DigitalMarketingChermsideWestComponent,
    DigitalMarketingClayfieldComponent,
    DigitalMarketingDeagonComponent,
    DigitalMarketingEagleFarmComponent,
    DigitalMarketingEvertonParkComponent,
    DigitalMarketingFitzgibbonComponent,
    DigitalMarketingGaythorneComponent,
    DigitalMarketingGeebungComponent,
    DigitalMarketingGordonParkComponent,
    DigitalMarketingGrangeComponent,
    DigitalMarketingHamiltonComponent,
    DigitalMarketingHendraComponent,
    DigitalMarketingKedronComponent,
    DigitalMarketingKeperraComponent,
    DigitalMarketingLutwycheComponent,
    DigitalMarketingMcdowallComponent,
    DigitalMarketingMitcheltonComponent,
    DigitalMarketingMyrtletownComponent,
    DigitalMarketingNewmarketComponent,
    DigitalMarketingNorthgateComponent,
    DigitalMarketingNudgeeComponent,
    DigitalMarketingNudgeeBeachComponent,
    DigitalMarketingNundahComponent,
    DigitalMarketingPinkenbaComponent,
    DigitalMarketingSandgateComponent,
    DigitalMarketingShorncliffeComponent,
    DigitalMarketingStaffordComponent,
    DigitalMarketingStaffordHeightsComponent,
    DigitalMarketingTaigumComponent,
    DigitalMarketingVirginiaComponent,
    DigitalMarketingWavellHeightsComponent,
    DigitalMarketingWilstonComponent,
    DigitalMarketingWindsorComponent,
    DigitalMarketingWooloowinComponent,
    DigitalMarketingZillmereComponent,
    DigitalMarketingAcaciaRidgeComponent,
    DigitalMarketingAlgesterComponent,
    DigitalMarketingAnnerleyComponent,
    DigitalMarketingArcherfieldComponent,
    DigitalMarketingBurbankComponent,
    DigitalMarketingCalamvaleComponent,
    DigitalMarketingCoopersPlainsComponent,
    DigitalMarketingDarraComponent,
    DigitalMarketingDoolandellaComponent,
    DigitalMarketingDrewvaleComponent,
    DigitalMarketingDurackComponent,
    DigitalMarketingDuttonParkComponent,
    DigitalMarketingEightMilePlainsComponent,
    DigitalMarketingEllenGroveComponent,
    DigitalMarketingFairfieldComponent,
    DigitalMarketingForestLakeComponent,
    DigitalMarketingGreenslopesComponent,
    DigitalMarketingHeathwoodComponent,
    DigitalMarketingHollandParkComponent,
    DigitalMarketingHollandParkWestComponent,
    DigitalMarketingInalaComponent,
    DigitalMarketingKarawathaComponent,
    DigitalMarketingKurabyComponent,
    DigitalMarketingLarapintaComponent,
    DigitalMarketingMacgregorComponent,
    DigitalMarketingMackenzieComponent,
    DigitalMarketingMansfieldComponent,
    DigitalMarketingMoorookaComponent,
    DigitalMarketingMountGravattComponent,
    DigitalMarketingMountGravattEastComponent,
    DigitalMarketingNathanComponent,
    DigitalMarketingPallaraComponent,
    DigitalMarketingParkinsonComponent,
    DigitalMarketingRichlandsComponent,
    DigitalMarketingRobertsonComponent,
    DigitalMarketingRochedaleComponent,
    DigitalMarketingRockleaComponent,
    DigitalMarketingRuncornComponent,
    DigitalMarketingSalisburyComponent,
    DigitalMarketingSeventeenMileRocksComponent,
    DigitalMarketingSinnamonParkComponent,
    DigitalMarketingStrettonComponent,
    DigitalMarketingSumnerComponent,
    DigitalMarketingSunnybankComponent,
    DigitalMarketingSunnybankHillsComponent,
    DigitalMarketingTarragindiComponent,
    DigitalMarketingTennysonComponent,
    DigitalMarketingUpperMountGravattComponent,
    DigitalMarketingWacolComponent,
    DigitalMarketingWillawongComponent,
    DigitalMarketingWishartComponent,
    DigitalMarketingYeerongpillyComponent,
    DigitalMarketingYerongaComponent,
    DigitalMarketingBalmoralComponent,
    DigitalMarketingBelmontComponent,
    DigitalMarketingBulimbaComponent,
    DigitalMarketingCampHillComponent,
    DigitalMarketingCannonHillComponent,
    DigitalMarketingCarinaComponent,
    DigitalMarketingCarinaHeightsComponent,
    DigitalMarketingCarindaleComponent,
    DigitalMarketingChandlerComponent,
    DigitalMarketingCoorparooComponent,
    DigitalMarketingGumdaleComponent,
    DigitalMarketingHawthorneComponent,
    DigitalMarketingHemmantComponent,
    DigitalMarketingLotaComponent,
    DigitalMarketingLyttonComponent,
    DigitalMarketingManlyComponent,
    DigitalMarketingManlyWestComponent,
    DigitalMarketingMoretonIslandComponent,
    DigitalMarketingMorningsideComponent,
    DigitalMarketingMurarrieComponent,
    DigitalMarketingNormanParkComponent,
    DigitalMarketingPortOfBrisbaneComponent,
    DigitalMarketingRansomeComponent,
    DigitalMarketingSevenHillsComponent,
    DigitalMarketingTingalpaComponent,
    DigitalMarketingWakerleyComponent,
    DigitalMarketingWynnumComponent,
    DigitalMarketingWynnumWestComponent,
    DigitalMarketingAnsteadComponent,
    DigitalMarketingAshgroveComponent,
    DigitalMarketingAuchenflowerComponent,
    DigitalMarketingBanksCreekComponent,
    DigitalMarketingBellbowrieComponent,
    DigitalMarketingBrookfieldComponent,
    DigitalMarketingChapelHillComponent,
    DigitalMarketingChelmerComponent,
    DigitalMarketingChuwarComponent,
    DigitalMarketingCorindaComponent,
    DigitalMarketingEnglandCreekComponent,
    DigitalMarketingEnoggeraComponent,
    DigitalMarketingEnoggeraReservoirComponent,
    DigitalMarketingFernyGroveComponent,
    DigitalMarketingFigTreePocketComponent,
    DigitalMarketingGracevilleComponent,
    DigitalMarketingIndooroopillyComponent,
    DigitalMarketingJamboreeHeightsComponent,
    DigitalMarketingJindaleeComponent,
    DigitalMarketingKaranaDownsComponent,
    DigitalMarketingKenmoreComponent,
    DigitalMarketingKenmoreHillsComponent,
    DigitalMarketingKholoComponent,
    DigitalMarketingLakeManchesterComponent,
    DigitalMarketingMiddleParkComponent,
    DigitalMarketingMiltonComponent,
    DigitalMarketingMoggillComponent,
    DigitalMarketingMountCootThaComponent,
    DigitalMarketingMountCrosbyComponent,
    DigitalMarketingMountOmmaneyComponent,
    DigitalMarketingOxleyComponent,
    DigitalMarketingPinjarraHillsComponent,
    DigitalMarketingPullenvaleComponent,
    DigitalMarketingRiverhillsComponent,
    DigitalMarketingSherwoodComponent,
    DigitalMarketingStLuciaComponent,
    DigitalMarketingTaringaComponent,
    DigitalMarketingTheGapComponent,
    DigitalMarketingToowongComponent,
    DigitalMarketingUpperBrookfieldComponent,
    DigitalMarketingUpperKedronComponent,
    DigitalMarketingWestlakeComponent,
    DigitalMarketingBulwerComponent,
    DigitalMarketingCowanCowanComponent,
    DigitalMarketingKooringalComponent,
    DigitalMarketingMoretonBayComponent
   
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    FormsModule,
    SharedModule,
    ScullyLibModule,
    ScrollToModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  exports: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [Meta],
  bootstrap: [AppComponent]
})
export class AppModule { }
