import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';  

@Component({
  selector: 'app-digital-marketing-acacia-ridge',
  templateUrl: './digital-marketing-acacia-ridge.component.html',
  styleUrls: ['./digital-marketing-acacia-ridge.component.css']
})
export class DigitalMarketingAcaciaRidgeComponent implements OnInit {

  constructor(private metaService: Meta) { 

    this.metaService.updateTag(
      { name: 'description', content: 'Dite Digital Marketing Acacia Ridge Is A Fully Stacked Digital Marketing Agency Located In Brisbane. Providing Solutions In Web, Search & Social.'},);

      this.metaService.updateTag(
      { name: 'keywords', content: 'dite acacia ridge, dite marketing acacia ridge, digital marketing acacia ridge, digital acacia ridge, digital accelerated acacia ridge, web acacia ridge, search acacia ridge, social acacia ridge, web page acacia ridge, web pages acacia ridge, web apps acacia ridge, web applications acacia ridge, progressive web apps acacia ridge, pwa acacia ridge, static web pages acacia ridge, search marketing acacia ridge, google ads acacia ridge, paid search acacia ridge, marketing acacia ridge, pay per click acacia ridge, ads management acacia ridge, organic search acacia ridge, search engine optimisation acacia ridge, acacia ridge digital marketing, acacia ridge digital agency, digital marketing agency acacia ridge, acacia ridge brisbane australia' },
    );

    this.metaService.updateTag(
      { name: 'subject', content: 'Digital Marketing Agency Acacia Ridge Brisbane Australia' },
    );

    this.metaService.updateTag(
      { name: 'topic', content: 'Digital Marketing Acacia Ridge' },
    );
  }

  ngOnInit(): void {
  }

}
