import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ScrollspyDirective } from './scrollspy.directive';

import { ClientsLogoComponent } from './clients-logo/clients-logo.component';
import { ServicesComponent } from './services/services.component';
import { PricingComponent } from './pricing/pricing.component';
import { FeaturesComponent } from './features/features.component';
import { BlogComponent } from './blog/blog.component';
import { SimplePricingComponent } from './simple-pricing/simple-pricing.component';
import { MemberComponent } from './member/member.component';
import { FooterComponent } from './footer/footer.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';


@NgModule({
  declarations: [
    ScrollspyDirective,
    ClientsLogoComponent,
    ServicesComponent,
    PricingComponent,
    FeaturesComponent,
    BlogComponent,
    SimplePricingComponent,
    MemberComponent,
    FooterComponent

  ],
  imports: [
    CommonModule,
    RouterModule,
    ScrollToModule

  ],
  exports: [
    ScrollspyDirective,
    ClientsLogoComponent, 
    ServicesComponent, 
    PricingComponent, 
    FeaturesComponent, 
    BlogComponent, 
    SimplePricingComponent,
    MemberComponent,
    FooterComponent
  ]
})

export class SharedModule { }
