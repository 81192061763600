import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-marketing-bellbowrie',
  templateUrl: './digital-marketing-bellbowrie.component.html',
  styleUrls: ['./digital-marketing-bellbowrie.component.css']
})
export class DigitalMarketingBellbowrieComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
