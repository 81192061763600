import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-marketing-wakerley',
  templateUrl: './digital-marketing-wakerley.component.html',
  styleUrls: ['./digital-marketing-wakerley.component.css']
})
export class DigitalMarketingWakerleyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
