<div class="row">
    <div class="col-lg-6 col-md-6 col-12" *ngFor="let data of pricingData">
        <div class="card pricing-rates business-rate shadow mt-5 bg-light rounded">
            <div class="card-body">
                <h4 class="title fw-bold text-uppercase text-primary text-center">{{data.title}}</h4>
                <div class="row">
                <div class="col-6 text-center">
                    <p>
                    <span class="h4"> $ </span>
                    <span class="price h4">{{data.priceUpfront}}</span><br>
                    <span class="price h4">{{data.tax}}</span><br>
                    <span class="price h4 mb-0">{{data.upfront}}</span><br>
                    <span class="h4 align-self-end mb-1">{{data.term}}</span></p>
                </div>
                <div class="col-6 text-center ">
                    <p><span class="h4 mb-0 mt-2"> $ </span>
                    <span class="price h4 mb-0">{{data.weekly}}</span><br>
                    <span class="price h4 mb-0">{{data.tax}}</span><br>
                    <span class="price h4 mb-0">{{data.prefix}}</span><br>
                    <span class="h4 align-self-end mb-1">{{data.term}}</span></p>
                </div>
                <div class="row text-center">
                    <h6>Includes {{data.list1title}} & {{data.list2title}}</h6>
                    <div class="col-6 mb-4 text-center">
                       
                <ul class="list-unstyled mb-0 ps-0" *ngFor="let item of data.list1">
                    <li class="text-muted mb-0"><span class="text-primary h5 me-2"><i class="bi bi-check-circle"></i></span>{{item}}</li>
                </ul>
            </div>
            <div class="col-6 mb-4 text-center">
                
                <ul class="list-unstyled mb-0 ps-0" *ngFor="let item of data.list2">
                    <li class="text-muted mb-0"><span class="text-primary h5 me-2"><i
                                class="bi bi-check-circle"></i></span>{{item}}</li>
                </ul>
            </div>
                </div>
                <div class="row col-12 justify-content-evenly">
                    <div class="btn btn-primary mt-4 col-5"><a href="{{data.linkUpfront}}" class="text-reset">Upfront</a></div>
                       <div class="btn btn-primary mt-4 col-5"><a href="{{data.linkWeekly}}" class="text-reset">Weekly</a></div>


             <!--   <a href="javascript:void(0)" class="btn btn-primary mt-4">{{data.btn}}</a>-->
            </div>  

                </div>
        </div>
    </div>
    <!--end col-->
</div>
