        <!-- Footer Start -->
        <footer class="footer">
            <div class="container ">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-0 mb-md-4 pb-0 pb-md-2 text-center ">
                        <a [ngxScrollTo]="'home'" class="logo-footer">
                            <img src="assets/images/dite-one-page/logo.png" height="80" alt="">
                        </a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center text-light">
                        <p class="mt-4">{{this.brand}} {{this.slogan}} {{this.location}} {{this.introService}} {{this.introCallToAction}}</p>
                        <!--end icon-->
                    </div>
                </div>        
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4 align-right text-center text-light">
                        <p class="mt-4">In the spirit of reconciliation, Dite Marketing acknowledges the Traditional Custodians of country throughout Australia and their connections to land, sea and community. We pay our respect to their elders past and present and extend that respect to all Aboriginal and Torres Strait Islander peoples today. We give great honour and our sincerest respect directly to the traditional land of the Turrbal mob as we proudly care for our people and community in Mianjin.</p>
                    </div>
                    <!--end col-->
                </div>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-0 mb-md-4 pb-0 pb-md-2 text-center text-light">
                        <ul class="list-unstyled social-icon social mb-0 mt-4" >
                            <li class="list-inline-item" ><a href="https://www.facebook.com/ditemarketing/" class="rounded">
                                <i class="bi bi-facebook" ></i>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="https://www.instagram.com/ditemarketing/" class="rounded">
                                <i class="bi bi-instagram"></i>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                <i class="bi bi-twitter"></i>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="https://www.linkedin.com/company/dite-marketing/" class="rounded">
                                <i class="bi bi-linkedin"></i>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </footer>
        <!-- Footer End -->
<!--end footer-->
<footer class="footer footer-bar">
    <div class="container text-center text-primary">
        <div class="row align-items-center">
            <div class="col-sm-12">
                <p class="mb-0"><a href="https://dite.com.au" class="text-reset">© 2021 Dite Marketing</a>
            </div>
        </div>
    </div>
</footer>
<!--<footer class="footer footer-bar">
    <div class="container text-center text-primary">
        <div class="row align-items-center">
            <div class="col-sm-12">
                <p class="mb-0">Built With <i class="bi bi-heart"></i><a href="https://0112.media" class="text-reset"> 0112 Media</a>.</p>
            </div>
        </div>
    </div>
</footer>-->
<!--end footer-->