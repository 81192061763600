import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-marketing-virginia',
  templateUrl: './digital-marketing-virginia.component.html',
  styleUrls: ['./digital-marketing-virginia.component.css']
})
export class DigitalMarketingVirginiaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
