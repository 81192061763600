import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-marketing-spring-hill',
  templateUrl: './digital-marketing-spring-hill.component.html',
  styleUrls: ['./digital-marketing-spring-hill.component.css']
})
export class DigitalMarketingSpringHillComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
