import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

/***
 * Footer Component
 */
export class FooterComponent implements OnInit {

  brand = 'Dite Marketing.';
  slogan = 'Digital Accelerated. Web. Search. Social.';
  location = "Proudly Located In Brisbane, Australia. Servicing All Corners Of The Globe.";
  introService = "Providing The Latest Technology & Strategy In Web, Search & Social.";
  introCallToAction = "Connect With Us Today.";
  subscribeHook = "Want To Keep In Touch Via Email. We Have Some Great Advice, Knowledge & Resounding Promotions To Share. Welcome To Our Digital Club. We'll Never Misuse Your Information As Per PPI Rules & Regulations. Unsubscribe Anytime!";

   constructor() { }

  ngOnInit(): void {
  }

}
