import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-marketing-fig-tree-pocket',
  templateUrl: './digital-marketing-fig-tree-pocket.component.html',
  styleUrls: ['./digital-marketing-fig-tree-pocket.component.css']
})
export class DigitalMarketingFigTreePocketComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
