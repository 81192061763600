<div class="row">
    <div class="col-xl-4 col-lg-4 col-md-6 mt-4 pt-2" *ngFor="let data of memberData">
      <div class="card team text-center border-0">
        <div class="p-4">
          <img src="{{data.profile}}" class="img-fluid avatar avatar-ex-large rounded-circle shadow"
            alt="team-member-profile-photo">
          <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
            <li class="list-inline-item ms-1" *ngFor="let hyperlink of data.twitterLink"><a href="{{hyperlink}}" class="rounded">
                <i-feather *ngFor="let item of data.twitter" name="{{item}}" class="bi bi-twitter"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1" *ngFor="let hyperlink of data.linkedinLink"><a href="{{hyperlink}}" class="rounded">
                <i-feather *ngFor="let item of data.linkedin" name="{{item}}" class="bi bi-linkedin"></i-feather>
              </a></li>
          </ul>
          <!--end icon-->
        </div>
        <div class="card-body py-3 px-0 content">
          <h5 class="mb-0"><a class="name text-dark">{{data.name}}</a></h5>
          <small class="designation text-muted">{{data.designation}}</small>
        </div>
      </div>
    </div>
</div>