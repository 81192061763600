import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-marketing-greenslopes',
  templateUrl: './digital-marketing-greenslopes.component.html',
  styleUrls: ['./digital-marketing-greenslopes.component.css']
})
export class DigitalMarketingGreenslopesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
